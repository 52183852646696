import { Trans } from '@lingui/macro'
import { ButtonOutlined } from 'components/Button'
import React from 'react'
import ReactGA from 'react-ga'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const Button = styled(ButtonOutlined).attrs(() => ({
  padding: '8px',
  $borderRadius: '8px',
  width: '48%',
  margin: '3px',
}))`
  color: ${({ theme }) => theme.text1};
  flex: 1;
`

export default function LeftRangeButton({ setLeftRangeToMin }: { setLeftRangeToMin: () => void }) {
  return (
    <Button
      onClick={() => {
        setLeftRangeToMin()
        ReactGA.event({
          category: 'Liquidity',
          action: 'Left Range To Min Clicked',
        })
      }}
    >
      <ThemedText.Body fontSize={12}>
        <Trans>Left Range To Min</Trans>
      </ThemedText.Body>
    </Button>
  )
}
