import { AutoColumn } from 'components/Column';
import { AutoRow } from 'components/Row';
import { SupportedChainId } from 'constants/chains';
import { Vault, VAULTS } from 'constants/vaults';
import { useActiveWeb3React } from 'hooks/web3';
import React from 'react';
import styled from 'styled-components/macro';
import { ExternalLink } from 'theme';
import { Ticks } from '.';

const Wrapper = styled.div`
  padding-top: 12px;
  font-size: 12px;
`;

export function Review({
  vault,
  rebalanceOption,
  baseLoPct,
  concentrateLimitPct,
  concentrateThresholdPct,
  currTicks,
  currentTick
}: {
  vault?: Vault;
  rebalanceOption: string;
  baseLoPct?: number;
  concentrateLimitPct?: number;
  concentrateThresholdPct?: number;
  currTicks?: Ticks;
  currentTick: string;
}) {
  const { chainId } = useActiveWeb3React();

  const vaults = VAULTS[chainId as SupportedChainId];
  const vaultName = Object.keys(vaults).find((key) => vaults[key].address === vault?.address);
  const externalLinkPrefix =
    chainId === SupportedChainId.POLYGON ? `https://polygonscan.com/address` : `https://etherscan.io/address`;

  return (
    <Wrapper>
      <AutoRow gap="5px">
        <b className="mr-5">Vault: </b> {vaultName?.toUpperCase()}
      </AutoRow>
      <AutoRow gap="5px">
        <b>Address: </b> <ExternalLink href={`${externalLinkPrefix}/${vault?.address}`}>{vault?.address}</ExternalLink>
      </AutoRow>
      <AutoRow gap="5px">
        <b>Selected Rebalance Option: </b>
        {`Member Token Ratio is ${rebalanceOption}`}
      </AutoRow>
      <AutoRow gap="5px">
        <b>Ticks:</b>
      </AutoRow>
      <AutoColumn gap="lg">baseLo: {currTicks?.baseLo}</AutoColumn>
      <AutoColumn gap="lg">baseUp: {currTicks?.baseUp}</AutoColumn>
      <AutoColumn gap="lg">limitLo: {currTicks?.limitLo}</AutoColumn>
      <AutoColumn gap="lg">limitUp: {currTicks?.limitUp}</AutoColumn>
      <AutoColumn gap="lg">current: {currentTick}</AutoColumn>
    </Wrapper>
  );
}
