import { BigNumber } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import { useCallback, useMemo } from 'react'

import { TransactionType } from '../state/transactions/actions'
import { useHasPendingMaxDeposit, useTransactionAdder } from '../state/transactions/hooks'
import { calculateGasMargin } from '../utils/calculateGasMargin'
import { useVaultContract } from './useContract'
import { useActiveWeb3React } from './web3'

export enum MaxDepositState {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
}

// returns a variable indicating the state of the max total supply setting and a function which sets max total supply if necessary or early returns
export function useMaxDepositCallback(
  vaultAddress?: string,
  maxDeposit0Input?: string,
  maxDeposit1Input?: string
): [() => Promise<void>] {
  const { chainId } = useActiveWeb3React()
  const pendingMaxDeposit = useHasPendingMaxDeposit(vaultAddress)

  const maxDepositState: MaxDepositState = useMemo(() => {
    return pendingMaxDeposit ? MaxDepositState.PENDING : MaxDepositState.IDLE
  }, [pendingMaxDeposit])

  const vaultContract = useVaultContract(vaultAddress)
  const addTransaction = useTransactionAdder()

  const maxDeposit = useCallback(async (): Promise<void> => {
    if (maxDepositState !== MaxDepositState.IDLE) {
      console.error('MaxDeposit was called unnecessarily')
      return
    }
    if (!chainId) {
      console.error('no chainId')
      return
    }
    if (!vaultAddress) {
      console.error('no vault')
      return
    }
    if (!vaultContract) {
      console.error('vaultContract is null')
      return
    }
    if (!maxDeposit0Input) {
      console.error('missing maxDeposit0 value')
      return
    }

    if (!maxDeposit1Input) {
      console.error('missing maxDeposit1 value')
      return
    }

    return vaultContract
      .setDepositMax(maxDeposit0Input + '000000000000000000', maxDeposit1Input + '000000000000000000', {
        gasLimit: calculateGasMargin(BigNumber.from('160000')),
      })
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          type: TransactionType.MAX_DEPOSIT,
          vaultAddress,
          maxDeposit0Value: maxDeposit0Input,
          maxDeposit1Value: maxDeposit1Input,
        })
      })
      .catch((error: Error) => {
        console.debug('Failed to set Max Deposit: ', error)
        throw error
      })
  }, [maxDepositState, maxDeposit0Input, maxDeposit1Input, chainId, vaultAddress, vaultContract, addTransaction])

  return [maxDeposit]
}
