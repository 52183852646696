/* eslint-disable prettier/prettier */
import { BigNumber } from '@ethersproject/bignumber';
import { Trans } from '@lingui/macro';
import { Currency, CurrencyAmount, Percent } from '@uniswap/sdk-core';
import { FeeAmount } from '@uniswap/v3-sdk';
import axios from 'axios';
import DowntimeWarning from 'components/DowntimeWarning';
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter';
import { SupportedChainId } from 'constants/chains';
import { useSimpleRebalanceV2Contract, useVaultContract } from 'hooks/useContract';
import { RebalanceState, useSimpleRebalanceV2Callback } from 'hooks/useSimpleRebalanceV2Callback';
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  useV3DerivedMintInfo,
  useV3MintActionHandlers,
  useV3MintState
} from 'state/mint/v3/hooks';
import isBaseCurrencyBaseToken from 'utils/isBaseCurrencyBaseToken';

import { ButtonLight, ButtonPrimary, ButtonText, ButtonSelect } from '../../components/Button';
import { AutoColumn } from '../../components/Column';
import FeeSelector from '../../components/FeeSelector';
import { SimpleRebalanceTab } from '../../components/NavigationTabs';
import { PositionPreview } from '../../components/PositionPreview';
import RateToggle from '../../components/RateToggle';
import Row, { RowBetween } from '../../components/Row';
import { SwitchLocaleLink } from '../../components/SwitchLocaleLink';
import { WETH9_EXTENDED } from '../../constants/tokens';
import {
  BNtoNumberWithoutDecimals,
  getVault,
  getVaultToken,
  initVaultStats,
  Vault,
  VaultStats
} from '../../constants/vaults';
import { useCurrency } from '../../hooks/Tokens';
import { useDerivedPositionInfo } from '../../hooks/useDerivedPositionInfo';
import { useIsSwapUnsupported } from '../../hooks/useIsSwapUnsupported';
import { useV3PositionFromTokenId } from '../../hooks/useV3Positions';
import { useActiveWeb3React } from '../../hooks/web3';
import { useWalletModalToggle } from '../../state/application/hooks';
import { Bound, Field } from '../../state/mint/v3/actions';
import { ThemedText } from '../../theme';
import { currencyId } from '../../utils/currencyId';
import { maxAmountSpend } from '../../utils/maxAmountSpend';
import { Dots } from '../Pool/styleds';
import SimpleRebalancePreview from './SimpleRebalancePreview';
import {
  CurrencyDropdown,
  DynamicSection,
  HideMedium,
  MediumOnly,
  PageWrapper,
  ResponsiveTwoColumns,
  RightContainer,
  ScrollablePage,
  Wrapper
} from './styled';

const DEFAULT_ADD_IN_RANGE_SLIPPAGE_TOLERANCE = new Percent(50, 10_000);

export type Ticks = {
  baseLo: number;
  baseUp: number;
  limitLo: number;
  limitUp: number;
};

export default function SimpleRebalanceV2({
  match: {
    params: { currencyIdA, currencyIdB, feeAmount: feeAmountFromUrl, tokenId }
  },
  history
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string; feeAmount?: string; tokenId?: string }>) {
  const { account, chainId } = useActiveWeb3React();
  const toggleWalletModal = useWalletModalToggle(); // toggle wallet when disconnected

  // check for existing position if tokenId in url
  const { position: existingPositionDetails, loading: positionLoading } = useV3PositionFromTokenId(
    tokenId ? BigNumber.from(tokenId) : undefined
  );
  const hasExistingPosition = !!existingPositionDetails && !positionLoading;
  const { position: existingPosition } = useDerivedPositionInfo(existingPositionDetails);

  // fee selection from url
  const feeAmount: FeeAmount | undefined =
    feeAmountFromUrl && Object.values(FeeAmount).includes(parseFloat(feeAmountFromUrl))
      ? parseFloat(feeAmountFromUrl)
      : undefined;

  // baseCurrency is the currency in the dropdown 1;
  // quoteCurrency is the currency in the dropdown 2
  const baseCurrency = useCurrency(currencyIdA);
  const currencyB = useCurrency(currencyIdB);
  // prevent an error if they input ETH/WETH
  const quoteCurrency =
    baseCurrency && currencyB && baseCurrency.wrapped.equals(currencyB.wrapped) ? undefined : currencyB;

  // mint state
  const { independentField, typedValue } = useV3MintState();

  const {
    ticks,
    dependentField,
    price,
    pricesAtTicks,
    parsedAmounts,
    currencyBalances,
    noLiquidity,
    currencies,
    invalidPool,
    invalidRange,
    outOfRange,
    depositADisabled,
    depositBDisabled,
    invertPrice,
    ticksAtLimit
  } = useV3DerivedMintInfo(
    baseCurrency ?? undefined,
    quoteCurrency ?? undefined,
    feeAmount,
    baseCurrency ?? undefined,
    existingPosition
  );

  const [baseTickLower, setBaseTickLower] = useState<number>(0);
  const [baseTickUpper, setBaseTickUpper] = useState<number>(0);
  const [limitTickLower, setLimitTickLower] = useState<number>(0);
  const [limitTickUpper, setLimitTickUpper] = useState<number>(0);

  const [currTicks, setCurrTicks] = useState<Ticks>({
    baseLo: 0,
    baseUp: 0,
    limitLo: 0,
    limitUp: 0
  });

  const [baseLoPct, setBaseLoPct] = useState<number>(0);
  const [limitUpPct, setLimitUpPct] = useState<number>(0);
  const [limitReserveThresholdPct, setLimitReserveThresholdPct] = useState<number>(0);
  const [healthThresholdPct, setHealthThresholdPct] = useState<number>(0);
  const [concentrateLimitPct, setConcentrateLimitPct] = useState<number>(0);
  const [concentrateThresholdPct, setConcentrateThresholdPct] = useState<number>(0);

  const [selectedRebalanceOption, setSelectedRebalanceOption] = useState<string>('');

  // oneToken should actually be called baseToken, as we have different kinds for HODL vaults now.
  const [vaultStats, setVaultStats] = useState<VaultStats>(initVaultStats);

  const { onFieldAInput, onFieldBInput, onLeftRangeInput, onRightRangeInput } = useV3MintActionHandlers(noLiquidity);

  const isValid = !invalidRange;

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  // capital efficiency warning
  const [showCapitalEfficiencyWarning, setShowCapitalEfficiencyWarning] = useState(false);

  useEffect(() => setShowCapitalEfficiencyWarning(false), [baseCurrency, quoteCurrency, feeAmount]);

  // txn values
  const [txHash, setTxHash] = useState<string>('');

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  };

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field])
      };
    },
    {}
  );

  const atMaxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0')
      };
    },
    {}
  );

  const vault =
    baseCurrency && quoteCurrency
      ? getVault(chainId, baseCurrency?.symbol, quoteCurrency?.symbol, feeAmount)
      : undefined;
  const vaultAddress = vault ? vault.address : undefined;
  const [rebalance, rebalanceCallback] = useSimpleRebalanceV2Callback(
    vault,
    vaultStats,
    baseLoPct,
    limitUpPct,
    limitReserveThresholdPct,
    healthThresholdPct
  );

  const vaultContract = useVaultContract(vaultAddress);
  const simpleRebalanceContract = useSimpleRebalanceV2Contract(chainId as SupportedChainId);

  useEffect(() => {
    setVaultStats(initVaultStats);
    getVaultStats();
  }, [vaultContract?.address, price !== undefined]);

  // should be: ifBaseCurrencyBaseToken (as opposed to memberToken)
  const isBaseCurrencyOneToken = isBaseCurrencyBaseToken(baseCurrency?.name, vault?.baseTokenName);
  
  const switchTokens = !(isBaseCurrencyOneToken === invertPrice);

  const simulateRebalance = async (
    vault?: Vault,
    baseLoPct?: number,
    limitUpPct?: number,
    limitReserveThresholdPct?: number,
    healthThresholdPct?: number
) => {
    if (!simpleRebalanceContract) return;

    if (!vault || vault.address === '') {
      console.error('no vault');
      return;
    }

    if (!baseLoPct || !limitUpPct || !limitReserveThresholdPct || !healthThresholdPct) {
      console.error('missing params for rebalance simulation');
      return;
    }

    try {
      const result: Ticks = await simpleRebalanceContract.simulate(
        vault.address,
        baseLoPct,
        limitUpPct,
        limitReserveThresholdPct,
        healthThresholdPct
      );
      setCurrTicks(result);
    } catch (e) {
      throw e;
    }
  };

  const getVaultStats = async () => {
    if (!vaultContract) return;

    const currentTick = (await vaultContract.currentTick()).toString();

    const token0 = await vaultContract.token0();
    const token1 = await vaultContract.token1();

    const allowToken0 = await vaultContract.allowToken0();

    // Pertaining to the oneBTC vault
    // Works with "0x111111517e4929D3dcbdfa7CCe55d30d4B6BC4d6"
    const vaultToken0 = getVaultToken(token0);
    // TODO: Left off Fails with "0x1f194578e7510A350fb517a9ce63C40Fa1899427"
    const vaultToken1 = getVaultToken(token1);

    const amounts = await vaultContract.getTotalAmounts();
    if (amounts && vaultToken0 && vaultToken1) {
      let memberTokenAmount = amounts.total0.toString();
      let oneTokenAmount = amounts.total1.toString();
      let memberTokenDecimals = vaultToken0.decimals;
      let oneTokenDecimals = vaultToken1.decimals;
      let memberTokenPriceAsStr = '0';
      let oneTokenPriceAsStr = '0';
      // price of token in the other token
      const memberTokenPriceFromVault = price
        ? switchTokens
          ? price.invert().toSignificant(6)
          : price.toSignificant(6)
        : '0';
      const oneTokenPriceFromVault = price
        ? switchTokens
          ? price.toSignificant(6)
          : price.invert().toSignificant(6)
        : '0';
      let memberTokenMarketCap = 0;
      let sevenDaysChange = 0;
      let vaultStrength = 0;
      let memberToken = vaultToken1;
      let oneToken = vaultToken0;
      // if (vaultToken1.memberToken) {
      if (allowToken0) {
        memberTokenAmount = amounts.total1.toString();
        oneTokenAmount = amounts.total0.toString();
      } else {
        memberToken = vaultToken0;
        oneToken = vaultToken1;
        memberTokenAmount = amounts.total0.toString();
        oneTokenAmount = amounts.total1.toString();
      }
      memberTokenDecimals = memberToken.decimals;
      oneTokenDecimals = oneToken.decimals;

      // if memberToken is ICHI v2 use the price of legacy ICHI from CoinGecko
      const memberTokenAddressForCoinGecko =
        memberToken.address === '0x903bEF1736CDdf2A537176cf3C64579C3867A881'
          ? '0x111111517e4929D3dcbdfa7CCe55d30d4B6BC4d6'
          : memberToken.address;
      if (vaultToken0.coingeckoId !== '' || vaultToken1.coingeckoId !== '') {
        const response = await axios.get(
          `https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=${memberTokenAddressForCoinGecko.toLowerCase()}&vs_currencies=usd&include_24hr_change=true`
        );
        if (
          response &&
          response.data &&
          JSON.parse(JSON.stringify(response.data))[memberToken.address.toLowerCase()]?.usd
        ) {
          memberTokenPriceAsStr = JSON.parse(JSON.stringify(response.data))[
            memberTokenAddressForCoinGecko.toLowerCase()
          ].usd;
        }
        const additionalResponse =
          memberToken.coingeckoId !== ''
            ? await axios.get(
                `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${memberToken.coingeckoId}&sparkline=false&price_change_percentage=7d`
              )
            : undefined;
        if (additionalResponse && additionalResponse.data && JSON.stringify(additionalResponse.data) !== '{}') {
          memberTokenMarketCap = Number(JSON.parse(JSON.stringify(additionalResponse.data))[0].market_cap);
          sevenDaysChange = Number(
            JSON.parse(JSON.stringify(additionalResponse.data))[0].price_change_percentage_7d_in_currency
          );
        }
        const response1 = await axios.get(
          `https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=${oneToken.address.toLowerCase()}&vs_currencies=usd&include_24hr_change=true`
        );
        if (
          response1 &&
          response1.data &&
          JSON.parse(JSON.stringify(response1.data))[oneToken.address.toLowerCase()]?.usd
        ) {
          oneTokenPriceAsStr = JSON.parse(JSON.stringify(response1.data))[oneToken.address.toLowerCase()].usd;
        }
      }

      const memberTokenPrice =
        memberTokenPriceAsStr === '0'
          ? Number(memberTokenPriceFromVault) * Number(oneTokenPriceAsStr)
          : Number(memberTokenPriceAsStr);

      const oneTokenPrice =
        oneTokenPriceAsStr === '0'
          ? Number(oneTokenPriceFromVault) * Number(memberTokenPriceAsStr)
          : Number(oneTokenPriceAsStr);

      const oneTokenValue = BNtoNumberWithoutDecimals(oneTokenAmount, oneTokenDecimals) * oneTokenPrice;
      const memberTokenValue = BNtoNumberWithoutDecimals(memberTokenAmount, memberTokenDecimals) * memberTokenPrice;
      // total value in baseTokens
      const oneTokenValueFromVault = BNtoNumberWithoutDecimals(oneTokenAmount, oneTokenDecimals) * 1;
      const memberTokenValueFromVault =
        BNtoNumberWithoutDecimals(memberTokenAmount, memberTokenDecimals) * Number(memberTokenPriceFromVault);
      const totalValue = oneTokenValue + memberTokenValue;
      const memberTokenRatio = Number(
        ((memberTokenValueFromVault / (memberTokenValueFromVault + oneTokenValueFromVault)) * 100).toFixed(2)
      );
      vaultStrength = memberTokenMarketCap !== 0 ? (oneTokenValue / memberTokenMarketCap) * 100 : 0;
      const result: VaultStats = {
        memberTokenAmount,
        memberTokenValue,
        memberTokenPrice,
        memberTokenRatio,
        oneTokenAmount,
        oneTokenValue,
        totalValue,
        vaultStrength,
        memberTokenMarketCap,
        sevenDaysChange,
        currentTick
      };
      setVaultStats(result);
    }
  };

  const handleCurrencySelect = useCallback(
    (currencyNew: Currency, currencyIdOther?: string): (string | undefined)[] => {
      const currencyIdNew = currencyId(currencyNew);

      if (currencyIdNew === currencyIdOther) {
        // not ideal, but for now clobber the other if the currency ids are equal
        return [currencyIdNew, undefined];
      } else {
        // prevent weth + eth
        const isETHOrWETHNew =
          currencyIdNew === 'ETH' || (chainId !== undefined && currencyIdNew === WETH9_EXTENDED[chainId]?.address);
        const isETHOrWETHOther =
          currencyIdOther !== undefined &&
          (currencyIdOther === 'ETH' ||
            (chainId !== undefined && currencyIdOther === WETH9_EXTENDED[chainId]?.address));

        if (isETHOrWETHNew && isETHOrWETHOther) {
          return [currencyIdNew, undefined];
        } else {
          return [currencyIdNew, currencyIdOther];
        }
      }
    },
    [chainId]
  );

  const handleCurrencyASelect = useCallback(
    (currencyANew: Currency) => {
      const [idA, idB] = handleCurrencySelect(currencyANew, currencyIdB);
      if (idB === undefined) {
        history.push(`/simplerebalancev2/${idA}`);
      } else {
        history.push(`/simplerebalancev2/${idA}/${idB}`);
      }
    },
    [handleCurrencySelect, currencyIdB, history]
  );

  const handleCurrencyBSelect = useCallback(
    (currencyBNew: Currency) => {
      const [idB, idA] = handleCurrencySelect(currencyBNew, currencyIdA);
      if (idA === undefined) {
        history.push(`/simplerebalancev2/${idB}`);
      } else {
        history.push(`/simplerebalancev2/${idA}/${idB}`);
      }
    },
    [handleCurrencySelect, currencyIdA, history]
  );

  const handleFeePoolSelect = useCallback(
    (newFeeAmount: FeeAmount) => {
      onLeftRangeInput('');
      onRightRangeInput('');
      history.push(`/simplerebalancev2/${currencyIdA}/${currencyIdB}/${newFeeAmount}`);
    },
    [currencyIdA, currencyIdB, history, onLeftRangeInput, onRightRangeInput]
  );

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false);
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onFieldAInput('');
      // dont jump to pool page if creating
      history.push('/pool');
    }
    setTxHash('');
  }, [history, onFieldAInput, txHash]);

  const addIsUnsupported = useIsSwapUnsupported(currencies?.CURRENCY_A, currencies?.CURRENCY_B);

  const clearAll = useCallback(() => {
    onFieldAInput('');
    onFieldBInput('');
    onLeftRangeInput('');
    onRightRangeInput('');
    history.push(`/simplerebalancev2`);
  }, [history, onFieldAInput, onFieldBInput, onLeftRangeInput, onRightRangeInput]);

  // get value and prices at ticks
  const { [Bound.LOWER]: priceLower, [Bound.UPPER]: priceUpper } = pricesAtTicks;

  const pendingText = `Supplying ${!depositADisabled ? parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) : ''} ${
    !depositADisabled ? currencies[Field.CURRENCY_A]?.symbol : ''
  } ${!outOfRange ? 'and' : ''} ${!depositBDisabled ? parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) : ''} ${
    !depositBDisabled ? currencies[Field.CURRENCY_B]?.symbol : ''
  }`;
  let memberInBasePercent = vault?.isInverted
    ? (
        ((Number(ticks.UPPER) - Number(vaultStats.currentTick)) / (Number(ticks.UPPER) - Number(ticks.LOWER))) *
        100
      ).toFixed(2)
    : (
        ((Number(ticks.LOWER) - Number(vaultStats.currentTick)) / (Number(ticks.LOWER) - Number(ticks.UPPER))) *
        100
      ).toFixed(2);
  if (Number(memberInBasePercent) < 0){
    memberInBasePercent = '0'
  };

  const rebalanceDisabled =
    rebalance === RebalanceState.PENDING ||
    baseTickLower === 0 ||
    baseTickUpper === 0 ||
    limitTickLower === 0 ||
    limitTickUpper === 0 ||
    (baseTickLower === limitTickLower && baseTickUpper === limitTickUpper);

  const isOverInventory =
    vaultStats && vaultStats?.memberTokenRatio ? vaultStats.memberTokenRatio < 7  : false;
  const isHealthy =
    vaultStats && vaultStats?.memberTokenRatio
      ? vaultStats?.memberTokenRatio >= 7 && vaultStats?.memberTokenRatio <= 25
      : false;
  const isUnderInventory =
    vaultStats && vaultStats?.memberTokenRatio ? vaultStats?.memberTokenRatio > 25 : false;

  const btnColor = (condition: boolean) => {
    return condition ? '#009966' : '#ffaa00';
  };

  const Buttons = () =>
    addIsUnsupported ? (
      <ButtonPrimary disabled={true} $borderRadius="12px" padding={'12px'}>
        <ThemedText.Main mb="4px">
          <Trans>Unsupported Asset</Trans>
        </ThemedText.Main>
      </ButtonPrimary>
    ) : !account ? (
      <ButtonLight onClick={toggleWalletModal} $borderRadius="12px" padding={'12px'}>
        <Trans>Connect Wallet</Trans>
      </ButtonLight>
    ) : (
      <AutoColumn gap={'md'}>
        {(rebalance === RebalanceState.PENDING || rebalance === RebalanceState.IDLE) && isValid && (
          <>
            {/* {vaultStats.memberTokenRatio} */}
            <RowBetween>
              <ButtonSelect
                onClick={() => {
                  setBaseLoPct(3000);
                  setLimitUpPct(2000);
                  setLimitReserveThresholdPct(700);
                  setHealthThresholdPct(7500);
                  setSelectedRebalanceOption('Over Inventory');
                  simulateRebalance(vault, 3000, 2000, 700, 7500);
                  setShowConfirm(true);
                }}
                disabled={!isOverInventory || invalidPool}
                width={'100%'}
                backgroundColor={btnColor(isOverInventory)}
              >
                {rebalance === RebalanceState.PENDING ? (
                  <Dots>
                    <Trans>Rebalancing</Trans>
                  </Dots>
                ) : (
                  <Trans>Over Inventory</Trans>
                )}
              </ButtonSelect>
            </RowBetween>
            <RowBetween>
              <ButtonSelect
                onClick={() => {
                  setShowConfirm(true);
                  setBaseLoPct(3000);
                  setLimitUpPct(2000);
                  setLimitReserveThresholdPct(700);
                  setHealthThresholdPct(7500);
                  setSelectedRebalanceOption('Healthy');
                  simulateRebalance(vault, 3000, 2000, 700, 7500);
                }}
                disabled={invalidPool || !isHealthy}
                width={'100%'}
                backgroundColor={btnColor(isHealthy)}
              >
                {rebalance === RebalanceState.PENDING ? (
                  <Dots>
                    <Trans>Rebalancing</Trans>
                  </Dots>
                ) : (
                  <Trans>Healthy</Trans>
                )}
              </ButtonSelect>
            </RowBetween>
            <RowBetween>
              <ButtonSelect
                onClick={() => {
                  setShowConfirm(true);
                  setBaseLoPct(3000);
                  setLimitUpPct(2000);
                  setLimitReserveThresholdPct(700);
                  setHealthThresholdPct(7500);
                  setSelectedRebalanceOption('Under Inventory');
                  simulateRebalance(vault, 3000, 2000, 700, 7500);
                }}
                disabled={invalidPool || !isUnderInventory}
                width={'100%'}
                backgroundColor={btnColor(isUnderInventory)}
              >
                {rebalance === RebalanceState.PENDING ? (
                  <Dots>
                    <Trans>Rebalancing</Trans>
                  </Dots>
                ) : (
                  <Trans>Under Inventory</Trans>
                )}
              </ButtonSelect>
            </RowBetween>
          </>
        )}
      </AutoColumn>
    );
  return (
    <>
      <ScrollablePage>
        <DowntimeWarning />
        <SimpleRebalancePreview
          isOpen={showConfirm}
          onDismiss={handleDismissConfirmation}
          txHash={txHash}
          vault={vault}
          rebalanceOption={selectedRebalanceOption}
          baseLoPct={baseLoPct}
          concentrateLimitPct={concentrateLimitPct}
          concentrateThresholdPct={concentrateThresholdPct}
          rebalanceCallback={rebalanceCallback}
          pendingText={pendingText}
          currTicks={currTicks}
          currentTick={vaultStats.currentTick}
        />
        {/* <TransactionConfirmationModal
          isOpen={showConfirm}
          onDismiss={handleDismissConfirmation}
          attemptingTxn={rebalance === RebalanceState.PENDING}
          hash={txHash}
          content={() => (
            <ConfirmationModalContent
              title={<Trans>Simple Rebalance Parameters</Trans>}
              onDismiss={handleDismissConfirmation}
              topContent={() => (
                <Review
                  vaultAddress={vaultAddress}
                  adminAddress={vault?.admin}
                  safeAddress={vault?.safe}
                  baseLoPct={baseLoPct}
                  concentrateLimitPct={concentrateLimitPct}
                  concentrateThresholdPct={concentrateThresholdPct}
                />
              )}
              bottomContent={() => (
                <ButtonPrimary style={{ marginTop: '1rem' }} onClick={rebalanceCallback}>
                  <Text fontWeight={500} fontSize={20}>
                    <Trans>Simple Rebalance</Trans>
                  </Text>
                </ButtonPrimary>
              )}
            />
          )}
          pendingText={pendingText}
        /> */}
        <PageWrapper wide={!hasExistingPosition}>
          <SimpleRebalanceTab
            creating={false}
            adding={true}
            positionID={tokenId}
            defaultSlippage={DEFAULT_ADD_IN_RANGE_SLIPPAGE_TOLERANCE}
            showBackLink={!hasExistingPosition}
            version="2"
          >
            {!hasExistingPosition && (
              <Row justifyContent="flex-end" style={{ width: 'fit-content', minWidth: 'fit-content' }}>
                <MediumOnly>
                  <ButtonText onClick={clearAll} margin="0 15px 0 0">
                    <ThemedText.Blue fontSize="12px">
                      <Trans>Clear All</Trans>
                    </ThemedText.Blue>
                  </ButtonText>
                </MediumOnly>
                {baseCurrency && quoteCurrency ? (
                  <RateToggle
                    currencyA={baseCurrency}
                    currencyB={quoteCurrency}
                    handleRateToggle={() => {
                      if (!ticksAtLimit[Bound.LOWER] && !ticksAtLimit[Bound.UPPER]) {
                        onLeftRangeInput((invertPrice ? priceLower : priceUpper?.invert())?.toSignificant(6) ?? '');
                        onRightRangeInput((invertPrice ? priceUpper : priceLower?.invert())?.toSignificant(6) ?? '');
                        onFieldAInput(formattedAmounts[Field.CURRENCY_B] ?? '');
                      }
                      history.push(
                        `/simplerebalance/${currencyIdB as string}/${currencyIdA as string}${
                          feeAmount ? '/' + feeAmount : ''
                        }`
                      );
                    }}
                  />
                ) : null}
              </Row>
            )}
          </SimpleRebalanceTab>
          <Wrapper>
            <ResponsiveTwoColumns wide={!hasExistingPosition}>
              <AutoColumn gap="lg">
                {!hasExistingPosition && (
                  <>
                    <AutoColumn gap="md">
                      <RowBetween paddingBottom="20px">
                        <ThemedText.Label>
                          <Trans>Select Pair</Trans>
                        </ThemedText.Label>
                      </RowBetween>
                      <RowBetween>
                        <CurrencyDropdown
                          value={formattedAmounts[Field.CURRENCY_A]}
                          onUserInput={onFieldAInput}
                          hideInput={true}
                          onMax={() => {
                            onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '');
                          }}
                          onCurrencySelect={handleCurrencyASelect}
                          showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
                          currency={currencies[Field.CURRENCY_A] ?? null}
                          id="add-liquidity-input-tokena"
                          showCommonBases
                        />

                        <div style={{ width: '12px' }} />

                        <CurrencyDropdown
                          value={formattedAmounts[Field.CURRENCY_B]}
                          hideInput={true}
                          onUserInput={onFieldBInput}
                          onCurrencySelect={handleCurrencyBSelect}
                          onMax={() => {
                            onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '');
                          }}
                          showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
                          currency={currencies[Field.CURRENCY_B] ?? null}
                          id="add-liquidity-input-tokenb"
                          showCommonBases
                        />
                      </RowBetween>

                      <FeeSelector
                        disabled={!quoteCurrency || !baseCurrency}
                        feeAmount={feeAmount}
                        handleFeePoolSelect={handleFeePoolSelect}
                        currencyA={baseCurrency ?? undefined}
                        currencyB={quoteCurrency ?? undefined}
                      />
                    </AutoColumn>{' '}
                  </>
                )}
                {hasExistingPosition && existingPosition && (
                  <PositionPreview
                    position={existingPosition}
                    title={<Trans>Selected Range</Trans>}
                    inRange={!outOfRange}
                    ticksAtLimit={ticksAtLimit}
                  />
                )}
              </AutoColumn>

              {!hasExistingPosition ? (
                <>
                  <HideMedium>
                    <Buttons />
                  </HideMedium>
                  <RightContainer gap="lg">
                    <DynamicSection gap="md" disabled={!feeAmount || invalidPool}>
                      <RowBetween>
                        <ThemedText.Label>
                          <Trans>Rebalance Action</Trans>
                        </ThemedText.Label>
                      </RowBetween>
                    </DynamicSection>

                    <MediumOnly>
                      <Buttons />
                    </MediumOnly>
                  </RightContainer>
                </>
              ) : (
                <Buttons />
              )}
            </ResponsiveTwoColumns>
          </Wrapper>
        </PageWrapper>
        {addIsUnsupported && (
          <UnsupportedCurrencyFooter
            show={addIsUnsupported}
            currencies={[currencies.CURRENCY_A, currencies.CURRENCY_B]}
          />
        )}
      </ScrollablePage>
      <SwitchLocaleLink />
    </>
  );
}
