import TransactionConfirmationModal, { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
import React from 'react'
import { RebalanceState } from 'hooks/useSimpleRebalanceCallback'
import { Trans } from '@lingui/macro'
import { Review } from './Review'
import { Vault } from 'constants/vaults'
import { ButtonPrimary } from 'components/Button'
import { Text } from 'rebass'
import { Ticks } from '.'

export default function SimpleRebalancePreview({
  isOpen,
  onDismiss,
  rebalance,
  txHash,
  vault,
  rebalanceOption,
  baseLoPct,
  concentrateLimitPct,
  concentrateThresholdPct,
  rebalanceCallback,
  pendingText,
  currTicks,
  currentTick
}: {
  isOpen: boolean
  onDismiss: () => void
  rebalance?: string
  txHash: string
  vault?: Vault
  rebalanceOption: string
  baseLoPct?: number
  concentrateLimitPct?: number
  concentrateThresholdPct?: number
  rebalanceCallback: () => void
  pendingText?: string
  currTicks?: Ticks
  currentTick: string
}) {

  return (
    <TransactionConfirmationModal
    isOpen={isOpen}
    onDismiss={onDismiss}
    attemptingTxn={rebalance === RebalanceState.PENDING}
    hash={txHash}
    content={() => (
      <ConfirmationModalContent
        title={<Trans>Simple Rebalance Parameters</Trans>}
        onDismiss={onDismiss}
        topContent={() => (
          <Review
            vault={vault}
            rebalanceOption={rebalanceOption}
            currTicks={currTicks}
            baseLoPct={baseLoPct}
            concentrateLimitPct={concentrateLimitPct}
            concentrateThresholdPct={concentrateThresholdPct}
            currentTick={currentTick}
          />
        )}
        bottomContent={() => (
          <ButtonPrimary style={{ marginTop: '1rem' }} onClick={rebalanceCallback}>
            <Text fontWeight={500} fontSize={20}>
              <Trans>*Rebalance</Trans>
            </Text>
          </ButtonPrimary>
        )}
      />
    )}
    pendingText={pendingText}
  />
)
}
