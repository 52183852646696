import { Ether, Token, WETH9 } from '@uniswap/sdk-core';

import { UNI_ADDRESS } from './addresses';
import { SupportedChainId } from './chains';

export const MATIC_POLYGON = new Token(
  SupportedChainId.POLYGON,
  '0x0000000000000000000000000000000000001010',
  18,
  'MATIC',
  'MATIC'
);
export const _1INCH = new Token(
  SupportedChainId.MAINNET,
  '0x111111111117dC0aa78b770fA6A738034120C302',
  18,
  '1INCH',
  '1INCH'
);
export const AAVE = new Token(
  SupportedChainId.MAINNET,
  '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9',
  18,
  'AAVE',
  'AAVE'
);
export const ALLY = new Token(
  SupportedChainId.MAINNET,
  '0x1aa1e61369874bae3444A8Ef6528d6b13D6952EF',
  18,
  'ALLY',
  'ALLY'
);
export const AMPL = new Token(
  SupportedChainId.MAINNET,
  '0xD46bA6D942050d489DBd938a2C909A5d5039A161',
  9,
  'AMPL',
  'Ampleforth'
);
export const BANK = new Token(
  SupportedChainId.MAINNET,
  '0x2d94AA3e47d9D5024503Ca8491fcE9A2fB4DA198',
  18,
  'BANK',
  'Bankless Token'
);
export const BAT = new Token(
  SupportedChainId.MAINNET,
  '0x0d8775f648430679a709e98d2b0cb6250d2887ef',
  18,
  'BAT',
  'BAT'
);
export const BIT = new Token(
  SupportedChainId.MAINNET,
  '0x1a4b46696b2bb4794eb3d4c26f1c55f9170fa4c5',
  18,
  'BIT',
  'BIT'
);
export const BNT = new Token(
  SupportedChainId.MAINNET,
  '0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c',
  18,
  'BNT',
  'Bancor(BNT)'
);
export const BOBA = new Token(
  SupportedChainId.MAINNET,
  '0x42bbfa2e77757c645eeaad1655e0911a7553efbc',
  18,
  'BOBA',
  'BOBA'
);
export const CEL = new Token(
  SupportedChainId.MAINNET,
  '0xaaAEBE6Fe48E54f431b0C390CfaF0b017d09D42d',
  4,
  'CEL',
  'Celsius(CEL)'
);
export const CHO = new Token(
  SupportedChainId.MAINNET,
  '0xbba39fd2935d5769116ce38d46a71bde9cf03099',
  18,
  'CHO',
  'CHO'
);
export const COC = new Token(
  SupportedChainId.MAINNET,
  '0xC8D9871a79551Ab4439C9E08f12962E3785f0437',
  18,
  'COC',
  'COC'
);
export const COMP = new Token(
  SupportedChainId.MAINNET,
  '0xc00e94cb662c3520282e6f5717214004a7f26888',
  18,
  'COMP',
  'COMP'
);
export const COW = new Token(
  SupportedChainId.MAINNET,
  '0xDEf1CA1fb7FBcDC777520aa7f396b4E015F497aB',
  18,
  'COW',
  'COW'
);
export const DAI = new Token(
  SupportedChainId.MAINNET,
  '0x6B175474E89094C44Da98b954EedeAC495271d0F',
  18,
  'DAI',
  'Dai Stablecoin'
);
export const DAI_ARBITRUM_ONE = new Token(
  SupportedChainId.ARBITRUM_ONE,
  '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
  18,
  'DAI',
  'Dai stable coin'
);
export const DAI_OPTIMISM = new Token(
  SupportedChainId.OPTIMISM,
  '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
  18,
  'DAI',
  'Dai stable coin'
);
export const FIDU = new Token(
  SupportedChainId.MAINNET,
  '0x6a445E9F40e0b97c92d0b8a3366cEF1d67F700BF',
  18,
  'FIDU',
  'FIDU'
);
export const FOX = new Token(SupportedChainId.MAINNET, '0xc770EEfAd204B5180dF6a14Ee197D99d808ee52d', 18, 'FOX', 'FOX');
export const FUSE = new Token(
  SupportedChainId.MAINNET,
  '0x970B9bB2C0444F5E81e9d0eFb84C8ccdcdcAf84d',
  18,
  'FUSE',
  'FUSE'
);
export const GIV = new Token(SupportedChainId.MAINNET, '0x900db999074d9277c5da2a43f252d74366230da0', 18, 'GIV', 'GIV ');
export const GNO = new Token(SupportedChainId.MAINNET, '0x6810e776880C02933D47DB1b9fc05908e5386b96', 18, 'GNO', 'GNO ');
export const GOVI_POLYGON = new Token(
  SupportedChainId.POLYGON, 
  '0x43Df9c0a1156c96cEa98737b511ac89D0e2A1F46', 
  18, 
  'GOVI', 
  'GOVI');
export const HOME = new Token(
  SupportedChainId.MAINNET,
  '0xb8919522331c59f5c16bdfaa6a121a6e03a91f62',
  6,
  'HOME',
  'HOME'
);
export const ICHI = new Token(
  SupportedChainId.MAINNET,
  '0x111111517e4929D3dcbdfa7CCe55d30d4B6BC4d6',
  18,
  'ICHI',
  'ICHI'
);
export const ICHI_POLYGON = new Token(
  SupportedChainId.POLYGON,
  '0x111111517e4929D3dcbdfa7CCe55d30d4B6BC4d6',
  18,
  'ICHI',
  'ICHI'
);
export const IMX = new Token(
  SupportedChainId.MAINNET,
  '0xf57e7e7c23978c3caec3c3548e3d615c346e79ff',
  18,
  'IMX',
  'IMX'
);
export const LDO = new Token(
  SupportedChainId.MAINNET,
  '0x5A98FcBEA516Cf06857215779Fd812CA3beF1B32',
  18,
  'LDO',
  'LDO'
);
export const LINK = new Token(
  SupportedChainId.MAINNET,
  '0x514910771af9ca656af840dff83e8264ecf986ca',
  18,
  'LINK',
  'LINK'
);
export const METIS = new Token(
  SupportedChainId.MAINNET,
  '0x9E32b13ce7f2E80A01932B42553652E053D6ed8e',
  18,
  'METIS',
  'METIS'
);
export const MIDAS = new Token(
  SupportedChainId.MAINNET,
  '0x97e6e31aFb2d93d437301e006D9DA714616766A5',
  18,
  'MIDAS',
  'MIDAS'
);
export const MKR = new Token(
  SupportedChainId.MAINNET,
  '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2',
  18,
  'MKR',
  'MKR'
);
export const OGN = new Token(
  SupportedChainId.MAINNET,
  '0x8207c1FfC5B6804F6024322CcF34F29c3541Ae26',
  18,
  'OGN',
  'OGN'
);
export const ONE_BTC_POLYGON = new Token(
  SupportedChainId.POLYGON,
  '0x1f194578e7510A350fb517a9ce63C40Fa1899427',
  18,
  'oneBTC',
  'oneBTC'
);
export const oneFOX = new Token(
  SupportedChainId.MAINNET,
  '0x03352D267951E96c6F7235037C5DFD2AB1466232',
  18,
  'oneFOX',
  'oneFOX'
);
export const oneFUSE = new Token(
  SupportedChainId.MAINNET,
  '0xbbce03b2e7f53cadca93251ca4c928af01db6404',
  18,
  'oneFUSE',
  'oneFUSE'
);
export const oneGIV = new Token(
  SupportedChainId.MAINNET,
  '0x17e6ba2519b4d15199b6529db340910ae031b1b0',
  18,
  'oneGIV',
  'oneGIV'
);
export const oneICHI = new Token(
  SupportedChainId.MAINNET,
  '0x4db2c02831c9ac305FF9311Eb661f80f1dF61e07',
  18,
  'oneICHI',
  'oneICHI'
);
export const OUSD = new Token(
  SupportedChainId.MAINNET,
  '0x2A8e1E676Ec238d8A992307B495b45B3fEAa5e86',
  18,
  'OUSD',
  'OUSD'
);
export const QRDO = new Token(
  SupportedChainId.MAINNET,
  '0x4123a133ae3c521FD134D7b13A2dEC35b56c2463',
  8,
  'QRDO',
  'QRDO'
);
export const sfrxETH = new Token(
  SupportedChainId.MAINNET,
  '0xac3E018457B222d93114458476f3E3416Abbe38F',
  18,
  'sfrxETH',
  'Staked Frax Ether'
);
export const SHIB = new Token(
  SupportedChainId.MAINNET,
  '0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE',
  18,
  'SHIB',
  'Shiba Inu'
);
export const SUSHI = new Token(
  SupportedChainId.MAINNET,
  '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
  18,
  'SUSHI',
  'SUSHI'
);
export const UMA = new Token(
  SupportedChainId.MAINNET,
  '0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828',
  18,
  'UMA',
  'UMA Voting Token v1'
);
export const USDC = new Token(
  SupportedChainId.MAINNET,
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  6,
  'USDC',
  'USD//C'
);
export const USDC_ARBITRUM = new Token(
  SupportedChainId.ARBITRUM_ONE,
  '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
  6,
  'USDC',
  'USD//C'
);
export const USDC_OPTIMISM = new Token(
  SupportedChainId.OPTIMISM,
  '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
  6,
  'USDC',
  'USD//C'
);
export const USDC_POLYGON = new Token(
  SupportedChainId.POLYGON,
  '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  6,
  'USDC',
  'USD//C'
);
export const USDT = new Token(
  SupportedChainId.MAINNET,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT',
  'Tether USD'
);
export const USDT_ARBITRUM_ONE = new Token(
  SupportedChainId.ARBITRUM_ONE,
  '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
  6,
  'USDT',
  'Tether USD'
);
export const USDT_OPTIMISM = new Token(
  SupportedChainId.OPTIMISM,
  '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
  6,
  'USDT',
  'Tether USD'
);
export const WBTC = new Token(
  SupportedChainId.MAINNET,
  '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  8,
  'WBTC',
  'Wrapped BTC'
);
export const WBTC_ARBITRUM_ONE = new Token(
  SupportedChainId.ARBITRUM_ONE,
  '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f',
  8,
  'WBTC',
  'Wrapped BTC'
);
export const WBTC_OPTIMISM = new Token(
  SupportedChainId.OPTIMISM,
  '0x68f180fcCe6836688e9084f035309E29Bf0A2095',
  8,
  'WBTC',
  'Wrapped BTC'
);
export const WBTC_POLYGON = new Token(
  SupportedChainId.POLYGON,
  '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
  8,
  'WBTC',
  'Wrapped BTC'
);
export const FEI = new Token(
  SupportedChainId.MAINNET,
  '0x956F47F50A910163D8BF957Cf5846D573E7f87CA',
  18,
  'FEI',
  'Fei USD'
);
export const TRIBE = new Token(
  SupportedChainId.MAINNET,
  '0xc7283b66Eb1EB5FB86327f08e1B5816b0720212B',
  18,
  'TRIBE',
  'Tribe'
);
export const FRAX = new Token(
  SupportedChainId.MAINNET,
  '0x853d955aCEf822Db058eb8505911ED77F175b99e',
  18,
  'FRAX',
  'Frax'
);
export const FXS = new Token(
  SupportedChainId.MAINNET,
  '0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0',
  18,
  'FXS',
  'Frax Share'
);
export const renBTC = new Token(
  SupportedChainId.MAINNET,
  '0xEB4C2781e4ebA804CE9a9803C67d0893436bB27D',
  8,
  'renBTC',
  'renBTC'
);
export const ETH2X_FLI = new Token(
  SupportedChainId.MAINNET,
  '0xAa6E8127831c9DE45ae56bB1b0d4D4Da6e5665BD',
  18,
  'ETH2x-FLI',
  'ETH 2x Flexible Leverage Index'
);
export const sETH2 = new Token(
  SupportedChainId.MAINNET,
  '0xFe2e637202056d30016725477c5da089Ab0A043A',
  18,
  'sETH2',
  'StakeWise Staked ETH2'
);
export const rETH2 = new Token(
  SupportedChainId.MAINNET,
  '0x20BC832ca081b91433ff6c17f85701B6e92486c5',
  18,
  'rETH2',
  'StakeWise Reward ETH2'
);
export const SWISE = new Token(
  SupportedChainId.MAINNET,
  '0x48C3399719B582dD63eB5AADf12A40B4C3f52FA2',
  18,
  'SWISE',
  'StakeWise'
);
export const UNI: { [chainId: number]: Token } = {
  [SupportedChainId.MAINNET]: new Token(SupportedChainId.MAINNET, UNI_ADDRESS[1], 18, 'UNI', 'Uniswap'),
  [SupportedChainId.RINKEBY]: new Token(SupportedChainId.RINKEBY, UNI_ADDRESS[4], 18, 'UNI', 'Uniswap'),
  [SupportedChainId.ROPSTEN]: new Token(SupportedChainId.ROPSTEN, UNI_ADDRESS[3], 18, 'UNI', 'Uniswap'),
  [SupportedChainId.GOERLI]: new Token(SupportedChainId.GOERLI, UNI_ADDRESS[5], 18, 'UNI', 'Uniswap'),
  [SupportedChainId.KOVAN]: new Token(SupportedChainId.KOVAN, UNI_ADDRESS[42], 18, 'UNI', 'Uniswap')
};

export const WETH9_EXTENDED: { [chainId: number]: Token } = {
  ...WETH9,
  [SupportedChainId.OPTIMISM]: new Token(
    SupportedChainId.OPTIMISM,
    '0x4200000000000000000000000000000000000006',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [SupportedChainId.OPTIMISTIC_KOVAN]: new Token(
    SupportedChainId.OPTIMISTIC_KOVAN,
    '0x4200000000000000000000000000000000000006',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [SupportedChainId.ARBITRUM_ONE]: new Token(
    SupportedChainId.ARBITRUM_ONE,
    '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [SupportedChainId.ARBITRUM_RINKEBY]: new Token(
    SupportedChainId.ARBITRUM_RINKEBY,
    '0xB47e6A5f8b33b3F17603C83a0535A9dcD7E32681',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  // https://polygonscan.com/token/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619
  [SupportedChainId.POLYGON]: new Token(
    SupportedChainId.POLYGON,
    // TODO: Double check this
    '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    18,
    'WETH',
    'Wrapped Ether'
  )
  // [SupportedChainId.POLYGON]: new Token(
  //   SupportedChainId.POLYGON,
  //   // TODO: Double check this
  //   '0x0000000000000000000000000000000000001010',
  //   18,
  //   'MATIC',
  //   'MATIC'
  // )
};
export const wNXM = new Token(
  SupportedChainId.MAINNET,
  '0x0d438F3b5175Bebc262bF23753C1E53d03432bDE',
  18,
  'wNXM',
  'wNXM'
);
export const YFI = new Token(
  SupportedChainId.MAINNET,
  '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e',
  18,
  'YFI',
  'YFI'
);
export const ZRX = new Token(
  SupportedChainId.MAINNET,
  '0xe41d2489571d322189246dafa5ebde1f4699f498',
  18,
  'ZRX',
  'ZRX'
);

// NOTE I'm re-using this for MATIC but likely won't work as intended here
export class ExtendedEther extends Ether {
  public get wrapped(): Token {
    if (this.chainId in WETH9_EXTENDED) return WETH9_EXTENDED[this.chainId];
    throw new Error('Unsupported chain ID');
  }

  private static _cachedEther: { [chainId: number]: ExtendedEther } = {};

  public static onChain(chainId: number): ExtendedEther {
    return this._cachedEther[chainId] ?? (this._cachedEther[chainId] = new ExtendedEther(chainId));
  }
}
