import { createReducer } from '@reduxjs/toolkit'

import {
  Field,
  resetMintState,
  setFullRange,
  setLeftRangeToMin,
  setRightRangeToMax,
  typeAffiliateInput,
  typeHysteresisInput,
  typeInput,
  typeLeftRangeInput,
  typeMaxDeposit0Input,
  typeMaxDeposit1Input,
  typeMaxTotalSupplyInput,
  typeRightRangeInput,
  typeStartPriceInput,
  typeTwapPeriodInput,
} from './actions'

export type FullRange = true

interface MintState {
  readonly independentField: Field
  readonly typedValue: string
  readonly startPriceTypedValue: string // for the case when there's no liquidity
  readonly leftRangeTypedValue: string | FullRange
  readonly rightRangeTypedValue: string | FullRange
  readonly twapPeriod: string
  readonly affiliate: string
  readonly hysteresis: string
  readonly maxTotalSupply: string
  readonly maxDeposit0: string
  readonly maxDeposit1: string
}

const initialState: MintState = {
  independentField: Field.CURRENCY_A,
  typedValue: '',
  startPriceTypedValue: '',
  leftRangeTypedValue: '',
  rightRangeTypedValue: '',
  twapPeriod: '',
  affiliate: '',
  hysteresis: '',
  maxTotalSupply: '',
  maxDeposit0: '',
  maxDeposit1: '',
}

export default createReducer<MintState>(initialState, (builder) =>
  builder
    .addCase(resetMintState, () => initialState)
    .addCase(setFullRange, (state) => {
      return {
        ...state,
        leftRangeTypedValue: true,
        rightRangeTypedValue: true,
      }
    })
    .addCase(setLeftRangeToMin, (state) => {
      return {
        ...state,
        leftRangeTypedValue: true,
      }
    })
    .addCase(setRightRangeToMax, (state) => {
      return {
        ...state,
        rightRangeTypedValue: true,
      }
    })
    .addCase(typeStartPriceInput, (state, { payload: { typedValue } }) => {
      return {
        ...state,
        startPriceTypedValue: typedValue,
      }
    })
    .addCase(typeLeftRangeInput, (state, { payload: { typedValue } }) => {
      return {
        ...state,
        leftRangeTypedValue: typedValue,
      }
    })
    .addCase(typeRightRangeInput, (state, { payload: { typedValue } }) => {
      return {
        ...state,
        rightRangeTypedValue: typedValue,
      }
    })
    .addCase(typeTwapPeriodInput, (state, { payload: { typedValue } }) => {
      return {
        ...state,
        twapPeriod: typedValue,
      }
    })
    .addCase(typeAffiliateInput, (state, { payload: { typedValue } }) => {
      return {
        ...state,
        affiliate: typedValue,
      }
    })
    .addCase(typeHysteresisInput, (state, { payload: { typedValue } }) => {
      return {
        ...state,
        hysteresis: typedValue,
      }
    })
    .addCase(typeMaxTotalSupplyInput, (state, { payload: { typedValue } }) => {
      return {
        ...state,
        maxTotalSupply: typedValue,
      }
    })
    .addCase(typeMaxDeposit0Input, (state, { payload: { typedValue } }) => {
      return {
        ...state,
        maxDeposit0: typedValue,
        deposit0Max: typedValue,
      }
    })
    .addCase(typeMaxDeposit1Input, (state, { payload: { typedValue } }) => {
      return {
        ...state,
        maxDeposit1: typedValue,
        deposit1Max: typedValue,
      }
    })
    .addCase(typeInput, (state, { payload: { field, typedValue, noLiquidity } }) => {
      if (noLiquidity) {
        // they're typing into the field they've last typed in
        if (field === state.independentField) {
          return {
            ...state,
            independentField: field,
            typedValue,
          }
        }
        // they're typing into a new field, store the other value
        else {
          return {
            ...state,
            independentField: field,
            typedValue,
          }
        }
      } else {
        return {
          ...state,
          independentField: field,
          typedValue,
        }
      }
    })
)
