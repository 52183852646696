import { Trans } from '@lingui/macro'
import Card from 'components/Card'
import { VaultStats } from 'constants/vaults'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components/macro'
import { ThemedText } from 'theme'

import { RowBetween } from '../Row'

export default function VaultStatsCard({ vaultStats }: { vaultStats: VaultStats }) {
  const theme = useContext(ThemeContext)

  if (vaultStats.currentTick === '') {
    return <></>
  }

  type ColorCode = {
    backgroundColor: string
    color: string
  }
  const codeYellow: ColorCode = {
    backgroundColor: 'rgba(243, 132, 30, 0.05)',
    color: theme.yellow1,
  }
  const codeOrange: ColorCode = {
    backgroundColor: 'rgba(243, 132, 30, 0.05)',
    color: '#f17425',
  }
  const codeRed: ColorCode = {
    backgroundColor: 'rgba(243, 132, 30, 0.05)',
    color: theme.red1,
  }
  const codeBlue: ColorCode = {
    backgroundColor: 'rgba(243, 132, 30, 0.05)',
    color: theme.blue2,
  }
  const codeMessage: ColorCode = {
    backgroundColor: theme.bg1,
    color: theme.blue2,
  }
  const codeNormal: ColorCode = {
    backgroundColor: theme.bg0,
    color: theme.text2,
  }
  let codeSevenDaysChange = codeNormal
  let codeMemberToken = codeNormal
  let codeVaultStrength = codeNormal
  let messageSevenDaysChange = ''
  let messageMemberToken = ''
  let messageVaultStrength = ''
  switch (true) {
    case vaultStats.sevenDaysChange > 25 && vaultStats.sevenDaysChange <= 50:
      codeSevenDaysChange = codeYellow
      messageSevenDaysChange = 'If (7 day price change > 25%), then Base_Order_Width = 2*Base_Order_Width'
      break
    case vaultStats.sevenDaysChange > 50 && vaultStats.sevenDaysChange <= 75:
      codeSevenDaysChange = codeOrange
      messageSevenDaysChange = 'If (7 day price change > 50%), then Base_Order_Width = 3*Base_Order_Width'
      break
    case vaultStats.sevenDaysChange > 75:
      codeSevenDaysChange = codeRed
      messageSevenDaysChange = 'If (7 day price change > 75%), then Base_Order_Width = 4*Base_Order_Width'
      break
    default:
      codeSevenDaysChange = codeNormal
      break
  }

  switch (true) {
    case vaultStats.memberTokenRatio && (vaultStats.memberTokenRatio > 25):
      codeMemberToken = codeRed
      messageMemberToken =
        'If (MemberToken% > 25%), Base_Order_Width = Base_Order_Width + 10 And Base Order includes 1 price space that is 100% Member Token'
      break
    case vaultStats.memberTokenRatio && (vaultStats.memberTokenRatio > 20 && vaultStats.memberTokenRatio <= 25):
      codeMemberToken = codeOrange
      messageMemberToken =
        'If (MemberToken% > 20%), Base_Order_Width = Base_Order_Width + 5 And Base Order includes 1 price space that is 100% Member Token'
      break
    case vaultStats.memberTokenRatio && (vaultStats.memberTokenRatio < 10 && vaultStats.memberTokenRatio >= 5):
      codeMemberToken = codeBlue
      messageMemberToken = 'If (Member Token% < 10%), Base_Order_Width = Base_Order_Width - 1'
      break
    case vaultStats.memberTokenRatio && (vaultStats.memberTokenRatio < 5 && vaultStats.memberTokenRatio > 0):
      codeMemberToken = codeYellow
      messageMemberToken = 'If (Member Token% < 5%), Base_Order_Width = Base_Order_Width - 2'
      break
    default:
      codeMemberToken = codeNormal
      break
  }

  switch (true) {
    case vaultStats.vaultStrength < 10 && vaultStats.vaultStrength >= 5:
      codeVaultStrength = codeYellow
      messageVaultStrength = 'If (Vault Strength < 10%), then Base_Order_Width = 2*Base_Order_Width'
      break
    case vaultStats.vaultStrength < 5 && vaultStats.vaultStrength >= 2:
      codeVaultStrength = codeOrange
      messageVaultStrength = 'If (Vault Strength < 5%), then Base_Order_Width = 3*Base_Order_Width'
      break
    case vaultStats.vaultStrength < 2 && vaultStats.vaultStrength > 0:
      codeVaultStrength = codeRed
      messageVaultStrength = 'If (Vault Strength < 2%), then Base_Order_Width = 4*Base_Order_Width'
      break
    default:
      codeVaultStrength = codeNormal
      break
  }

  const showRules =
    codeSevenDaysChange !== codeNormal || codeMemberToken !== codeNormal || codeVaultStrength !== codeNormal

  return (
    <>
      <ThemedText.Label>
        <Trans>Vault Stats</Trans>
      </ThemedText.Label>
      <ThemedText.Main>
        <Card backgroundColor={codeMemberToken.backgroundColor} color={codeMemberToken.color} padding="2px 10px">
          <RowBetween>
            <span>Member Token Ratio:</span> <span>{vaultStats.memberTokenRatio}%</span>
          </RowBetween>
        </Card>
        <Card backgroundColor={codeNormal.backgroundColor} color={codeNormal.color} padding="2px 10px">
          <RowBetween>
            <span>Member Token Value:</span> <span>${vaultStats.memberTokenValue.toFixed(0)}</span>
          </RowBetween>
          <RowBetween>
            <span>oneToken Value:</span>
            <span>${vaultStats.oneTokenValue.toFixed(0)}</span>
          </RowBetween>
          <RowBetween>
            <span>Total Vault Value:</span> <span>${vaultStats.totalValue.toFixed(0)}</span>
          </RowBetween>
          <RowBetween>
            <span>Member Token Market Cap:</span> <span>${vaultStats.memberTokenMarketCap}</span>
          </RowBetween>
        </Card>
        <Card
          backgroundColor={codeSevenDaysChange.backgroundColor}
          color={codeSevenDaysChange.color}
          padding="2px 10px"
        >
          <RowBetween>
            <span>Seven days change:</span> <span>{vaultStats.sevenDaysChange.toFixed(2)}%</span>
          </RowBetween>
        </Card>
        <Card backgroundColor={codeVaultStrength.backgroundColor} color={codeVaultStrength.color} padding="2px 10px">
          <RowBetween>
            <span>Vault Strength:</span> <span>{vaultStats.vaultStrength.toFixed(2)}%</span>
          </RowBetween>
        </Card>
      </ThemedText.Main>
      {showRules && (
        <ThemedText.Small>
          {messageMemberToken && (
            <Card backgroundColor={codeMessage.backgroundColor} padding="5px 10px">
              * {messageMemberToken}
            </Card>
          )}
          {messageSevenDaysChange && (
            <Card backgroundColor={codeMessage.backgroundColor} padding="5px 10px">
              ** {messageSevenDaysChange}
            </Card>
          )}
          {messageVaultStrength && (
            <Card backgroundColor={codeMessage.backgroundColor} padding="5px 10px">
              *** {messageVaultStrength}
            </Card>
          )}
        </ThemedText.Small>
      )}
    </>
  )
}
