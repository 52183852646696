import { SupportedChainId } from './chains';

export function getVault(chainId?: number, token0?: string, token1?: string, fee?: number): Vault {
  const emptyVault = {
    address: '',
    admin: '',
    safe: '',
    isInverted: true
  };
  if (!chainId || !token0 || !token1 || !fee) return emptyVault;

  let vaultName = token0 + '-' + token1 + '-' + fee;
  if (token0.toLowerCase() > token1.toLowerCase()) {
    vaultName = token1 + '-' + token0 + '-' + fee;
  }
  vaultName = vaultName.toLowerCase();

  return VAULTS[chainId][vaultName] ? VAULTS[chainId][vaultName] : emptyVault;
}

export function getVaultToken(address: string): VaultToken | undefined {
  switch (address.toLowerCase()) {
    case '0x111111517e4929D3dcbdfa7CCe55d30d4B6BC4d6'.toLowerCase():
      return { address, decimals: 18, name: 'ichi', coingeckoId: 'ichi-farm' } as VaultToken;
    case '0x111111111117dC0aa78b770fA6A738034120C302'.toLowerCase():
      return { address, decimals: 18, name: '1inch', coingeckoId: '1inch' } as VaultToken;
    case '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9'.toLowerCase():
      return { address, decimals: 18, name: 'aave', coingeckoId: 'aave' } as VaultToken;
    case '0x1aa1e61369874bae3444A8Ef6528d6b13D6952EF'.toLowerCase():
      return { address, decimals: 18, name: 'ally', coingeckoId: '' } as VaultToken;
    case '0x2d94AA3e47d9D5024503Ca8491fcE9A2fB4DA198'.toLowerCase():
      return { address, decimals: 18, name: 'bank', coingeckoId: 'bankless-dao' } as VaultToken;
    case '0x0d8775f648430679a709e98d2b0cb6250d2887ef'.toLowerCase():
      return { address, decimals: 18, name: 'bat', coingeckoId: 'basic-attention-token' } as VaultToken;
    case '0x1a4b46696b2bb4794eb3d4c26f1c55f9170fa4c5'.toLowerCase():
      return { address, decimals: 18, name: 'bit', coingeckoId: 'bitdao' } as VaultToken;
    case '0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c'.toLowerCase():
      return { address, decimals: 18, name: 'bnt', coingeckoId: 'bancor-network' } as VaultToken;
    case '0x42bbfa2e77757c645eeaad1655e0911a7553efbc'.toLowerCase():
      return { address, decimals: 18, name: 'boba', coingeckoId: 'boba-network' } as VaultToken;
    case '0xaaAEBE6Fe48E54f431b0C390CfaF0b017d09D42d'.toLowerCase():
      return { address, decimals: 4, name: 'cel', coingeckoId: 'celsius-network-token' } as VaultToken;
    case '0xBBa39Fd2935d5769116ce38d46a71bde9cf03099'.toLowerCase():
      return { address, decimals: 18, name: 'cho', coingeckoId: 'choise-com' } as VaultToken;
    case '0xC8D9871a79551Ab4439C9E08f12962E3785f0437'.toLowerCase():
      return { address, decimals: 18, name: 'coc', coingeckoId: '' } as VaultToken;
    case '0xc00e94cb662c3520282e6f5717214004a7f26888'.toLowerCase():
      return { address, decimals: 18, name: 'comp', coingeckoId: 'compound' } as VaultToken;
    case '0xDEf1CA1fb7FBcDC777520aa7f396b4E015F497aB'.toLowerCase():
      return { address, decimals: 18, name: 'cow', coingeckoId: 'cow-protocol' } as VaultToken;
    case '0x6a445E9F40e0b97c92d0b8a3366cEF1d67F700BF'.toLowerCase():
      return { address, decimals: 18, name: 'fidu', coingeckoId: 'fidu' } as VaultToken;
    case '0xc770EEfAd204B5180dF6a14Ee197D99d808ee52d'.toLowerCase():
      return { address, decimals: 18, name: 'fox', coingeckoId: 'shapeshift-fox-token' } as VaultToken;
    case '0x853d955acef822db058eb8505911ed77f175b99e'.toLowerCase():
      return { address, decimals: 18, name: 'frax', coingeckoId: 'frax' } as VaultToken;
    case '0x970B9bB2C0444F5E81e9d0eFb84C8ccdcdcAf84d'.toLowerCase():
      return { address, decimals: 18, name: 'fuse', coingeckoId: 'fuse-network-token' } as VaultToken;
    case '0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0'.toLowerCase():
      return { address, decimals: 18, name: 'fxs', coingeckoId: 'frax-share' } as VaultToken;
    case '0x900db999074d9277c5da2a43f252d74366230da0'.toLowerCase():
      return { address, decimals: 18, name: 'giv', coingeckoId: 'giveth' } as VaultToken;
    case '0x6810e776880C02933D47DB1b9fc05908e5386b96'.toLowerCase():
      return { address, decimals: 18, name: 'gno', coingeckoId: 'gnosis' } as VaultToken;
    case '0xb8919522331c59f5c16bdfaa6a121a6e03a91f62'.toLowerCase():
      return { address, decimals: 6, name: 'home', coingeckoId: '' } as VaultToken;
    case '0xf57e7e7c23978c3caec3c3548e3d615c346e79ff'.toLowerCase():
      return { address, decimals: 18, name: 'imx', coingeckoId: 'immutablex' } as VaultToken;
    case '0x5a98fcbea516cf06857215779fd812ca3bef1b32'.toLowerCase():
      return { address, decimals: 18, name: 'ldo', coingeckoId: 'lido-dao' } as VaultToken;
    case '0x514910771af9ca656af840dff83e8264ecf986ca'.toLowerCase():
      return { address, decimals: 18, name: 'link', coingeckoId: 'chainlink' } as VaultToken;
    case '0x9e32b13ce7f2e80a01932b42553652e053d6ed8e'.toLowerCase():
      return { address, decimals: 18, name: 'metis', coingeckoId: 'metis-token' } as VaultToken;
    case '0x97e6e31afb2d93d437301e006d9da714616766a5'.toLowerCase():
      return { address, decimals: 18, name: 'midas', coingeckoId: 'midas' } as VaultToken;
    case '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2'.toLowerCase():
      return { address, decimals: 18, name: 'mkr', coingeckoId: 'maker' } as VaultToken;
    case '0x8207c1ffc5b6804f6024322ccf34f29c3541ae26'.toLowerCase():
      return { address, decimals: 18, name: 'ogn', coingeckoId: 'origin-protocol' } as VaultToken;
    case '0x03352D267951E96c6F7235037C5DFD2AB1466232'.toLowerCase():
      return { address, decimals: 18, name: 'onefox', coingeckoId: '' } as VaultToken;
    case '0xbbce03b2e7f53cadca93251ca4c928af01db6404'.toLowerCase():
      return { address, decimals: 18, name: 'onefuse', coingeckoId: '' } as VaultToken;
    case '0x17e6ba2519b4d15199b6529db340910ae031b1b0'.toLowerCase():
      return { address, decimals: 18, name: 'onegiv', coingeckoId: '' } as VaultToken;
    case '0x4db2c02831c9ac305FF9311Eb661f80f1dF61e07'.toLowerCase():
      return { address, decimals: 18, name: 'oneichi', coingeckoId: '' } as VaultToken;
    case '0x2a8e1e676ec238d8a992307b495b45b3feaa5e86'.toLowerCase():
      return { address, decimals: 18, name: 'ousd', coingeckoId: 'origin-dollar' } as VaultToken;
    case '0x4123a133ae3c521FD134D7b13A2dEC35b56c2463'.toLowerCase():
      return { address, decimals: 8, name: 'qrdo', coingeckoId: 'qredo' } as VaultToken;
    case '0xac3E018457B222d93114458476f3E3416Abbe38F'.toLowerCase():
      return { address, decimals: 18, name: 'sfrxeth', coingeckoId: '' } as VaultToken;
    case '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce'.toLowerCase():
      return { address, decimals: 18, name: 'shib', coingeckoId: 'shiba-inu' } as VaultToken;
    case '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2'.toLowerCase():
      return { address, decimals: 18, name: 'sushi', coingeckoId: 'sushi' } as VaultToken;
    case '0x48c3399719b582dd63eb5aadf12a40b4c3f52fa2'.toLowerCase():
      return { address, decimals: 18, name: 'swise', coingeckoId: 'stakewise' } as VaultToken;
    case '0x04fa0d235c4abf4bcf4787af4cf447de572ef828'.toLowerCase():
      return { address, decimals: 18, name: 'uma', coingeckoId: 'uma' } as VaultToken;
    case '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984'.toLowerCase():
      return { address, decimals: 18, name: 'uni', coingeckoId: 'uniswap' } as VaultToken;
    case '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'.toLowerCase():
      return { address, decimals: 6, name: 'usdc', coingeckoId: 'usd-coin' } as VaultToken;
    case '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599'.toLowerCase():
      return { address, decimals: 8, name: 'wbtc', coingeckoId: 'wrapped-bitcoin' } as VaultToken;
    case '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'.toLowerCase():
      return { address, decimals: 18, name: 'weth', coingeckoId: 'weth' } as VaultToken;
    case '0x0d438f3b5175bebc262bf23753c1e53d03432bde'.toLowerCase():
      return { address, decimals: 18, name: 'wnxm', coingeckoId: 'wrapped-nxm' } as VaultToken;
    case '0x1f194578e7510A350fb517a9ce63C40Fa1899427'.toLowerCase():
      return { address, decimals: 18, name: 'onebtc', coingeckoId: '' } as VaultToken;
    case '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6'.toLowerCase():
      return { address, decimals: 8, name: 'wbtc', coingeckoId: '' } as VaultToken;
    case '0x43Df9c0a1156c96cEa98737b511ac89D0e2A1F46'.toLowerCase():
      return { address, decimals: 18, name: 'govi', coingeckoId: '' } as VaultToken;
    case '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174'.toLowerCase():
      return { address, decimals: 6, name: 'usdc', coingeckoId: '' } as VaultToken;
    case '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e'.toLowerCase():
      return { address, decimals: 18, name: 'yfi', coingeckoId: 'yearn-finance' } as VaultToken;
    case '0xe41d2489571d322189246dafa5ebde1f4699f498'.toLowerCase():
      return { address, decimals: 18, name: 'zrx', coingeckoId: '0x' } as VaultToken;
  }
  return;
}

export function BNtoNumberWithoutDecimals(val: string, decimals: number): number {
  if (val != null) {
    const digits = val.length;
    let tempVal = '';
    if (digits <= decimals) {
      tempVal = '0.';
      for (let i = 0; i < decimals - digits; i++) {
        tempVal = `${tempVal}0`;
      }
      tempVal = `${tempVal}${val}`;
    } else {
      for (let i = 0; i < digits - decimals; i++) {
        tempVal = `${tempVal}${val[i]}`;
      }
      tempVal = `${tempVal}.`;
      for (let i = digits - decimals; i < digits; i++) {
        tempVal = `${tempVal}${val[i]}`;
      }
    }
    return Number(tempVal);
  }
  return 0;
}

export type VaultToken = {
  address: string;
  decimals: number;
  name: string;
  memberToken: boolean;
  coingeckoId: string;
};

export type Vault = {
  address: string;
  admin: string;
  safe: string;
  isInverted: boolean;
  baseTokenName?: string;
};

export type VaultMapping = {
  [vault in string]: Vault;
};

export type Vaults = {
  [chainId: number]: VaultMapping;
};

export type VaultStats = {
  memberTokenAmount: string;
  memberTokenValue: number;
  memberTokenPrice: number;
  memberTokenRatio?: number;
  oneTokenAmount: string;
  oneTokenValue: number;
  totalValue: number;
  vaultStrength: number;
  memberTokenMarketCap: number;
  sevenDaysChange: number;
  currentTick: string;
};

export const initVaultStats: VaultStats = {
  memberTokenAmount: '0',
  memberTokenValue: 0,
  memberTokenPrice: 0,
  memberTokenRatio: undefined,
  oneTokenAmount: '0',
  oneTokenValue: 0,
  totalValue: 0,
  vaultStrength: 0,
  memberTokenMarketCap: 0,
  sevenDaysChange: 0,
  currentTick: ''
};

// simplified rebalance strategy contract
export const STRATEGY_DEFAULT_SIMPLIFIED_ADDRESS: { [chainId: number]: string } = {
  [SupportedChainId.MAINNET]: '0x23146B474158b2a7cdAfcE966d80ECEb1FFfAA6F',
  [SupportedChainId.POLYGON]: '0x7087DdA31FcCF1Abf089e6af27BA6687F3356103'
};

// simplified rebalance strategy contract V2
export const STRATEGY_DEFAULT_SIMPLIFIED_V2_ADDRESS: { [chainId: number]: string } = {
  [SupportedChainId.MAINNET]: '0xaffC276D3c0Fb0DBaBF0b4CE0A3605FD5837b1EF',
  [SupportedChainId.POLYGON]: '0x131c03ca881B7cC66d7a5120A9273ebf675C241D'
};

// tokens in the vault names arranged alphabetically
export const VAULTS: Vaults = {
  [SupportedChainId.MAINNET]: {
    '1inch-ichi-3000': {
      address: '0x65953959EdA6f0085D75B6e6E1F44212AB71c55A',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: false
    },
    'aave-ichi-3000': {
      address: '0xA4209504c7a56502DaF39110064c49401C77D0B8',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'ally-ichi-10000': {
      address: '0xE0b60aC202530017E5f06561156531c3AdfC5c8f',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'bank-ichi-3000': {
      address: '0x49492cb4e60E32B149C2af35156c3757fCA147a9',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'bat-ichi-3000': {
      address: '0xB6c52337AEc343008cEF9134b80da5429d9196d8',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: false
    },
    'bit-ichi-3000': {
      address: '0xdc6Ee42cC2B0b0af968Fa27092b5c061CddDA8fE',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'bnt-ichi-3000': {
      address: '0x4DFa8455658f4d6D2eeb91CbeFb0Ee94056bB3dC',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'boba-weth-3000': {
      address: '0x12a819EC5085A82A72D9BB3Cdf78408271121677',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: false,
      baseTokenName: 'Boba Network'
    },
    'boba-ichi-3000': {
      address: '0xb1765340b4526ab67b9929546a2aeeb440326ed0',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'cel-ichi-3000': {
      address: '0x5fEb9A87A9C7d05C9Fbf7D24e753ceEE6696f10D',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'cho-ichi-3000': {
      address: '0x88dde82A09d35551cE77F206bFB1D27d2fF80fbE',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'coc-ichi-3000': {
      address: '0xf38f545F8c3117c00282E85B6Cc12bb2CBBA76C1',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'coc-usdc-3000': {
      address: '0xE3Ab7528D38214b0D53911fAd72339512873D864',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'coc-weth-3000': {
      address: '0xfeee972ffe5F0BDa462130F88076158c1F9e561D',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'comp-ichi-3000': {
      address: '0x1e0C4D5030f3766C1B8A6D46c2f89aA4dbb92448',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'cow-ichi-3000': {
      address: '0xE88952D8D7F8ECDf5e54574C5Ef3e198D831ea91',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'fidu-ichi-10000': {
      address: '0x169f655711345FF16dDa4Ccd29cE1cb9C897Eb90',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'fox-onefox-10000': {
      address: '0x779F9BAd1f4B1Ef5198AD9361DBf3791F9e0D596',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: false
    },
    'frax-ichi-10000': {
      address: '0x793eD8760f48fB457ABBd646aA3a0e9c0000347b',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'fuse-ichi-3000': {
      address: '0xF6d4cdF6A9a82Aa56d2F2E1825B9f8E6052d8C46',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'fuse-onefuse-10000': {
      address: '0x3A4411a33CfeF8BC01f23ED7518208aA38cca824',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'fxs-ichi-3000': {
      address: '0x690654F78988D9C7e0dB40dc4a51e9c3b363DEEB',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'giv-weth-3000': {
      address: '0xd46904b107b249E64D28F66a4B6BAC2E6AC1b953',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: false,
      baseTokenName: "Giveth"
    },
    'giv-ichi-3000': {
      address: '0x7a2410f9285374C01947B72cB44DD36388582762',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true,
      baseTokenName: "Giveth"
    },
    'giv-onegiv-10000': {
      address: '0xc3151A58d519B94E915f66B044De3E55F77c2dd9',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: false
    },
    'gno-ichi-3000': {
      address: '0xd9E3646f5f6F491c0011796C0f7eC45C6639c4C6',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'home-ichi-10000': {
      address: '0x54114cc2eafc82410c9c21bf40519406957d6c44',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'ichi-ldo-3000': {
      address: '0x53FcdD1e5C2bC05fc848E7a77273132bF891DE9d',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'ichi-link-3000': {
      address: '0x3C0F29979095dA9a6f0a516c17D4Fe83202ef427',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'ichi-metis-3000': {
      address: '0x18379571f965098D6539726d0E51ea48120C5277',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'ichi-midas-3000': {
      address: '0x5Edda12a9BBfa368c839D9de44047493991084Cb',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'ichi-mkr-3000': {
      address: '0xa4aDF19196BC401a31e6E22E203c4A2fA0d1886a',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'ichi-ogn-3000': {
      address: '0xF01F687873cAfd318FECB11f8f4fD3D02512c974',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'ichi-oneichi-10000': {
      address: '0x46f9490bcbcd0A12d3d8578B5b3AB19f8EF0617D',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'ichi-ousd-10000': {
      address: '0x7Ee8773Ab15A237657E418dB3642C2eb1e6Fec7d',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'ichi-sfrxeth-10000': {
      address: '0xe52bCB075d0dCD994da43a343d16bec77314858d',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'ichi-shib-3000': {
      address: '0x93a09a584F26952C0573b46fAA78820C778357B9',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'ichi-sushi-3000': {
      address: '0xCa121a7030302c1192896293c8d16d24d323E191',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'ichi-swise-3000': {
      address: '0xA3016D1565f97f1600729cAf6E42132188cA100A',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'ichi-uma-3000': {
      address: '0x4C9523d75cC520b9ED5f24275A680B95daCA903A',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: false
    },
    'ichi-uni-3000': {
      address: '0x9526C7FC4d0d73212d331D84BE6F1EdE06E7BEcD',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'ichi-usdc-100': {
      address: '0x540b0d9AF7004a78Aa58a391718344c6897AB49A',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true,
      baseTokenName: 'ICHI'
    },
    'ichi-usdc-10000': {
      address: '0x683F081DBC729dbD34AbaC708Fa0B390d49F1c39',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'ichi-wbtc-100': {
      address: '0x21e8625531993AC26f0DEAFa4083Fdf8f6EC3771',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true,
      baseTokenName: 'ICHI'
    },
    'ichi-wbtc-3000': {
      address: '0x913b7D91e019402233d2f75863133925CE658CD9',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'ichi-weth-100': {
      address: '0xD22d97bB77e6BDf8D3f81Ca8FFe58f58B7505282',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true,
      baseTokenName: 'ICHI'
    },
    'ichi-weth-500': {
      address: '0xF5777E4b828Fc08001222eC4A72978Ce727C8a8C',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'ichi-wnxm-3000': {
      address: '0x8abb986fB2C72aBc5a08f4D34BaF15279Dd5581F',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: false
    },
    'ichi-yfi-3000': {
      address: '0x91E4d4d930c5B68B384a81866b03d288223cb41b',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: false
    },
    'ichi-zrx-3000': {
      address: '0xfCB8273E895e8f435153b0e556dB807376032802',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'imx-ichi-3000': {
      address: '0x742F29C97e2610e1D142C0906C4eC7b38F194DAa',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'imx-weth-3000': {
      address: '0x65C6AbAa85Dd7E374502B0101b6C1F54a5aD725f',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
    'qrdo-usdc-10000': {
      address: '0x784Ac9aaeaB58AAf904cc69e105aa51343E4C693',
      admin: '0x59A1DEA067733e6e13581479830D7Ed667bEa106',
      safe: '0xfF7B5E167c9877f2b9f65D19d9c8c9aa651Fe19F',
      isInverted: true
    },
  },
  [SupportedChainId.KOVAN]: {
    'ichi-oneuni-10000': {
      address: '0x601644dA9D85202AF94D05F4d5908b98BfD5A90f',
      admin: '',
      safe: '',
      isInverted: true
    },
    'ichi-weenus-3000': {
      address: '0x3f52009b2b52CAaA4A4ae6c56A883435eC68CC43',
      admin: '',
      safe: '',
      isInverted: true
    }
  },
  [SupportedChainId.POLYGON]: {
    'govi-ichi-3000': {
      address: '0x70Aa3c8e256c859e52c0B8C263f763D9051B95e1', // vault
      admin: '0x7087DdA31FcCF1Abf089e6af27BA6687F3356103',
      safe: '0x0768A75F616B98ee0937673bD83B7aBF142236Ea',
      isInverted: true
    },
    'ichi-onebtc-10000': {
      address: '0xE5bf5D33C617556B91558aAfb7BeadB68E9Cea81', // vault
      admin: '0x7087DdA31FcCF1Abf089e6af27BA6687F3356103',
      safe: '0x0768A75F616B98ee0937673bD83B7aBF142236Ea',
      isInverted: true
    },
    'ichi-usdc-10000': {
      address: '0x711901e4b9136119Fb047ABe8c43D49339f161c3', // vault
      admin: '0x7087DdA31FcCF1Abf089e6af27BA6687F3356103',
      safe: '0x0768A75F616B98ee0937673bD83B7aBF142236Ea',
      isInverted: true
    },
    'ichi-wbtc-3000': {
      address: '0x4aEF5144131dB95c110af41c8Ec09f46295a7C4B', // vault
      admin: '0x7087DdA31FcCF1Abf089e6af27BA6687F3356103',
      safe: '0x0768A75F616B98ee0937673bD83B7aBF142236Ea',
      isInverted: true
    },
    'usdc-weth-500': {
      address: '0x3ac9b3db3350A515c702ba19a001d099d4a5F132', // vault
      admin: '0x7087DdA31FcCF1Abf089e6af27BA6687F3356103',
      safe: '0x0768A75F616B98ee0937673bD83B7aBF142236Ea',
      isInverted: false,
      baseTokenName: 'USDCoin'
    }
  }
};
