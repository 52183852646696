/**
 * checks if base currency is base token of the vauls
 */
export default function isBaseCurrencyBaseToken(currencyName: string | undefined, baseTokenName: string | undefined) {
  if (!baseTokenName){
    // if base token name is undefined, these are 3 possible member tokens: ICHI, QRDO and GIV, everything else is base token
    return   currencyName !== 'ICHI' &&
    currencyName !== 'Qredo' &&
    currencyName !== 'Giveth';
  } else {
    return currencyName === baseTokenName;
  }


}
