import { BigNumber } from '@ethersproject/bignumber';
import { TransactionResponse } from '@ethersproject/providers';
import { SupportedChainId } from 'constants/chains';
import { Vault, VaultStats } from 'constants/vaults';
import { useCallback, useMemo } from 'react';

import { TransactionType } from '../state/transactions/actions';
import { useTransactionAdder } from '../state/transactions/hooks';
import { calculateGasMargin } from '../utils/calculateGasMargin';
import { useSimpleRebalanceContract } from './useContract';
import { useActiveWeb3React } from './web3';

export enum RebalanceState {
  IDLE = 'IDLE',
  PENDING = 'PENDING'
}

// returns a variable indicating the state of the rebalance and a function which rebalances if necessary or early returns
export function useSimpleRebalanceCallback(
  vault?: Vault,
  stats?: VaultStats,
  baseLoPct?: number,
  concentrateLimitPct?: number,
  concentrateThresholdPct?: number
): [RebalanceState, () => Promise<void>] {
  const { chainId } = useActiveWeb3React();
  // const pendingRebalance = useHasPendingRebalance(vaultAddress)
  const pendingRebalance = false;

  const rebalanceState: RebalanceState = useMemo(() => {
    return pendingRebalance ? RebalanceState.PENDING : RebalanceState.IDLE;
  }, [pendingRebalance]);

  // const vaultContract = useVaultContract(vault?.address)
  // const vaultAdmin1Contract = useVaultAdmin1Contract(vault?.admin)
  const simpleRebalanceContact = useSimpleRebalanceContract(chainId as SupportedChainId);
  const addTransaction = useTransactionAdder();

  const rebalance = useCallback(async (): Promise<void> => {
    if (rebalanceState !== RebalanceState.IDLE) {
      console.error('rebalance was called unnecessarily');
      return;
    }
    if (!chainId) {
      console.error('no chainId');
      return;
    }
    if (!vault || vault.address === '') {
      console.error('no vault');
      return;
    }
    // if (!baseLowerTick || !baseUpperTick || !limitLowerTick || !limitUpperTick) {
    //   console.error('missing ticks')
    //   return
    // }
    if (!stats) {
      console.error('no stats');
      return;
    }
    if (!baseLoPct || !concentrateLimitPct || !concentrateThresholdPct) {
      console.error(`missing paramas: [${baseLoPct}][${concentrateLimitPct}][${concentrateThresholdPct}]`);
      return;
    }

    /*const estimatedGas = await vaultContract.estimateGas.rebalance(
      baseLowerTick.toString(),
      baseUpperTick.toString(),
      limitLowerTick.toString(),
      limitUpperTick.toString(),
      swap ? swap : '0'
    )*/

    if (!simpleRebalanceContact) {
      console.error('no vault admin');
      return;
    }

    /*console.log(vault.safe)
      console.log(vault.address)
      console.log(stats.currentTick)
      console.log(baseLowerTick)
      console.log(baseUpperTick)
      console.log(limitLowerTick)
      console.log(limitUpperTick)*/

    return simpleRebalanceContact
      .rebalance(
        vault.safe,
        vault.address,
        stats.currentTick,
        baseLoPct,
        concentrateLimitPct,
        concentrateThresholdPct,
        {
          gasLimit: calculateGasMargin(BigNumber.from('1600000'))
        }
      )
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          type: TransactionType.SIMPLE_REBALANCE,
          vaultAddress: vault.address,
          baseLoPct,
          concentrateLimitPct,
          concentrateThresholdPct
        });
      })
      .catch((error: Error) => {
        console.debug('Failed to rebalance vault', error);
        throw error;
      });
  }, [
    rebalanceState,
    chainId,
    vault,
    stats,
    simpleRebalanceContact,
    baseLoPct,
    concentrateThresholdPct,
    concentrateLimitPct,
    addTransaction
  ]);

  return [rebalanceState, rebalance];
}
