/* eslint-disable prettier/prettier */
import { BigNumber } from '@ethersproject/bignumber'
import { Trans } from '@lingui/macro'
import { Currency, CurrencyAmount, Percent } from '@uniswap/sdk-core'
import { FeeAmount } from '@uniswap/v3-sdk'
import AddressInputPanel from 'components/AddressInputPanel'
import DowntimeWarning from 'components/DowntimeWarning'
import CurrencyInputPanel from 'components/InputPanel'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import { useAffiliateCallback } from 'hooks/useAffiliateCallback'
import { useHysteresisCallback } from 'hooks/useHysteresisCallback'
import { useMaxDepositCallback } from 'hooks/useMaxDepositCallback'
import { useMaxTotalSupplyCallback } from 'hooks/useMaxTotalSupplyCallback'
import { useTwapPeriodCallback } from 'hooks/useTwapPeriodCallback'
import React, { useCallback, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Text } from 'rebass'
// import { useBurnActionHandlers } from 'state/burn/hooks'
import {
  useAffiliateHandlers,
  useHysteresisHandlers,
  useMaxDepositHandlers,
  useMaxTotalSupplyHandlers,
  useTwapPeriodHandlers,
  useV3DerivedMintInfo,
  useV3MintActionHandlers,
  useV3MintState,
} from 'state/mint/v3/hooks'

import { ButtonLight, ButtonPrimary, ButtonSecondary, ButtonText } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import FeeSelector from '../../components/FeeSelector'
import { VaultSettingsTab } from '../../components/NavigationTabs'
// import { Input as NumericalInput } from '../../components/NumericalInput'
import { PositionPreview } from '../../components/PositionPreview'
// import PresetsButtons from '../../components/RangeSelector/PresetsButtons'
import RateToggle from '../../components/RateToggle'
import Row, { RowBetween } from '../../components/Row'
import { SwitchLocaleLink } from '../../components/SwitchLocaleLink'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModal'
import { WETH9_EXTENDED } from '../../constants/tokens'
import { getVault } from '../../constants/vaults'
import { useCurrency } from '../../hooks/Tokens'
import { useDerivedPositionInfo } from '../../hooks/useDerivedPositionInfo'
import { useIsSwapUnsupported } from '../../hooks/useIsSwapUnsupported'
import { RebalanceState, useRebalanceCallback } from '../../hooks/useRebalanceCallback'
import { useV3PositionFromTokenId } from '../../hooks/useV3Positions'
import { useActiveWeb3React } from '../../hooks/web3'
import { useWalletModalToggle } from '../../state/application/hooks'
import { Bound, Field } from '../../state/mint/v3/actions'
import { ThemedText } from '../../theme'
import { currencyId } from '../../utils/currencyId'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { Review } from './Review'
import {
  CurrencyDropdown,
  DynamicSection,
  HideMedium,
  MediumOnly,
  PageWrapper,
  ResponsiveTwoColumns,
  RightContainer,
  ScrollablePage,
  Wrapper,
} from './styled'

const DEFAULT_ADD_IN_RANGE_SLIPPAGE_TOLERANCE = new Percent(50, 10_000)

export default function Settings({
  match: {
    params: { currencyIdA, currencyIdB, feeAmount: feeAmountFromUrl, tokenId },
  },
  history,
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string; feeAmount?: string; tokenId?: string }>) {
  const { account, chainId } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle() // toggle wallet when disconnected

  // check for existing position if tokenId in url
  const { position: existingPositionDetails, loading: positionLoading } = useV3PositionFromTokenId(
    tokenId ? BigNumber.from(tokenId) : undefined
  )
  const hasExistingPosition = !!existingPositionDetails && !positionLoading
  const { position: existingPosition } = useDerivedPositionInfo(existingPositionDetails)

  // fee selection from url
  const feeAmount: FeeAmount | undefined =
    feeAmountFromUrl && Object.values(FeeAmount).includes(parseFloat(feeAmountFromUrl))
      ? parseFloat(feeAmountFromUrl)
      : undefined

  const baseCurrency = useCurrency(currencyIdA)
  const currencyB = useCurrency(currencyIdB)
  // prevent an error if they input ETH/WETH
  const quoteCurrency =
    baseCurrency && currencyB && baseCurrency.wrapped.equals(currencyB.wrapped) ? undefined : currencyB

  // mint state
  const { independentField, typedValue, twapPeriod, hysteresis, maxTotalSupply, affiliate, maxDeposit0, maxDeposit1 } = useV3MintState()

  const {
    dependentField,
    pricesAtTicks,
    parsedAmounts,
    currencyBalances,
    // position,
    noLiquidity,
    currencies,
    // errorMessage,
    outOfRange,
    depositADisabled,
    depositBDisabled,
    invertPrice,
    ticksAtLimit,
  } = useV3DerivedMintInfo(
    baseCurrency ?? undefined,
    quoteCurrency ?? undefined,
    feeAmount,
    baseCurrency ?? undefined,
    existingPosition
  )

  const baseTickLower = 0;
  const baseTickUpper = 0;
  const limitTickLower = 0;
  const limitTickUpper = 0;

  const { onFieldAInput, onFieldBInput, onLeftRangeInput, onRightRangeInput } =
    useV3MintActionHandlers(noLiquidity)

  const { onFieldTwapInput } =
    useTwapPeriodHandlers()
  
  const { onFieldAffiliateInput } =
    useAffiliateHandlers()
  
  const { onFieldHysteresisInput } =
    useHysteresisHandlers()
  
    const { onFieldMaxTotalSupplyInput } =
    useMaxTotalSupplyHandlers()
  
    const { onFieldMaxDeposit0Input, onFieldMaxDeposit1Input } =
    useMaxDepositHandlers()
  
  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)

  // txn values
  const [txHash, setTxHash] = useState<string>('')

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  }

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field]),
      }
    },
    {}
  )

  const atMaxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0'),
      }
    },
    {}
  )

  const vault = baseCurrency && quoteCurrency ? getVault(chainId, baseCurrency?.symbol, quoteCurrency?.symbol, feeAmount) : undefined
  const vaultAddress = vault ? vault.address : undefined
  const [rebalance, rebalanceCallback] = useRebalanceCallback(
    vault, undefined, baseTickLower, baseTickUpper, limitTickLower, limitTickUpper, '0'
  )

  const [twapPeriodCallback] = useTwapPeriodCallback(
    vaultAddress, twapPeriod
  )

  const [affiliateCallback] = useAffiliateCallback(
    vaultAddress, affiliate
  )

  const [hysteresisCallback] = useHysteresisCallback(
    vaultAddress, hysteresis
  )

  const [maxTotalSupplyCallback] = useMaxTotalSupplyCallback(
    vaultAddress, maxTotalSupply
  )

  const [maxDepositCallback] = useMaxDepositCallback(
    vaultAddress, maxDeposit0, maxDeposit1
  )

  const handleCurrencySelect = useCallback(
    (currencyNew: Currency, currencyIdOther?: string): (string | undefined)[] => {
      const currencyIdNew = currencyId(currencyNew)

      if (currencyIdNew === currencyIdOther) {
        // not ideal, but for now clobber the other if the currency ids are equal
        return [currencyIdNew, undefined]
      } else {
        // prevent weth + eth
        const isETHOrWETHNew =
          currencyIdNew === 'ETH' || (chainId !== undefined && currencyIdNew === WETH9_EXTENDED[chainId]?.address)
        const isETHOrWETHOther =
          currencyIdOther !== undefined &&
          (currencyIdOther === 'ETH' || (chainId !== undefined && currencyIdOther === WETH9_EXTENDED[chainId]?.address))

        if (isETHOrWETHNew && isETHOrWETHOther) {
          return [currencyIdNew, undefined]
        } else {
          return [currencyIdNew, currencyIdOther]
        }
      }
    },
    [chainId]
  )

  const handleCurrencyASelect = useCallback(
    (currencyANew: Currency) => {
      const [idA, idB] = handleCurrencySelect(currencyANew, currencyIdB)
      if (idB === undefined) {
        history.push(`/settings/${idA}`)
      } else {
        history.push(`/settings/${idA}/${idB}`)
      }
    },
    [handleCurrencySelect, currencyIdB, history]
  )

  const handleCurrencyBSelect = useCallback(
    (currencyBNew: Currency) => {
      const [idB, idA] = handleCurrencySelect(currencyBNew, currencyIdA)
      if (idA === undefined) {
        history.push(`/settings/${idB}`)
      } else {
        history.push(`/settings/${idA}/${idB}`)
      }
    },
    [handleCurrencySelect, currencyIdA, history]
  )

  const handleFeePoolSelect = useCallback(
    (newFeeAmount: FeeAmount) => {
      onLeftRangeInput('')
      onRightRangeInput('')
      history.push(`/settings/${currencyIdA}/${currencyIdB}/${newFeeAmount}`)
    },
    [currencyIdA, currencyIdB, history, onLeftRangeInput, onRightRangeInput]
  )

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onFieldAInput('')
      // dont jump to pool page if creating
      history.push('/pool')
    }
    setTxHash('')
  }, [history, onFieldAInput, txHash])

  const addIsUnsupported = useIsSwapUnsupported(currencies?.CURRENCY_A, currencies?.CURRENCY_B)

  const clearAll = useCallback(() => {
    onFieldAInput('')
    onFieldBInput('')
    onLeftRangeInput('')
    onRightRangeInput('')
    history.push(`/settings`)
  }, [history, onFieldAInput, onFieldBInput, onLeftRangeInput, onRightRangeInput])

  // get value and prices at ticks
  const { [Bound.LOWER]: priceLower, [Bound.UPPER]: priceUpper } = pricesAtTicks

  const pendingText = `Supplying ${!depositADisabled ? parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) : ''} ${
    !depositADisabled ? currencies[Field.CURRENCY_A]?.symbol : ''
  } ${!outOfRange ? 'and' : ''} ${!depositBDisabled ? parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) : ''} ${
    !depositBDisabled ? currencies[Field.CURRENCY_B]?.symbol : ''
  }`

  const Buttons = () =>
    addIsUnsupported ? (
      <ButtonPrimary disabled={true} $borderRadius="12px" padding={'12px'}>
        <ThemedText.Main mb="4px">
          <Trans>Unsupported Asset</Trans>
        </ThemedText.Main>
      </ButtonPrimary>
    ) : !account ? (
      <ButtonLight onClick={toggleWalletModal} $borderRadius="12px" padding={'12px'}>
        <Trans>Connect Wallet</Trans>
      </ButtonLight>
    ) : (<div></div>)
  return (
    <>
      <ScrollablePage>
        <DowntimeWarning />
        <TransactionConfirmationModal
          isOpen={showConfirm}
          onDismiss={handleDismissConfirmation}
          attemptingTxn={rebalance === RebalanceState.PENDING}
          hash={txHash}
          content={() => (
            <ConfirmationModalContent
              title={<Trans>Rebalance Parameters</Trans>}
              onDismiss={handleDismissConfirmation}
              topContent={() => (
                <Review
                  vaultAddress={vaultAddress}
                  baseTickLower={baseTickLower}
                  baseTickUpper={baseTickUpper}
                  limitTickLower={limitTickLower}
                  limitTickUpper={limitTickUpper}
                  swap={'0'}
                />
              )}
              bottomContent={() => (
                <ButtonPrimary style={{ marginTop: '1rem' }} onClick={rebalanceCallback}>
                  <Text fontWeight={500} fontSize={20}>
                    <Trans>Rebalance</Trans>
                  </Text>
                </ButtonPrimary>
              )}
            />
          )}
          pendingText={pendingText}
        />
        <PageWrapper wide={!hasExistingPosition}>
          <VaultSettingsTab
            creating={false}
            adding={true}
            positionID={tokenId}
            defaultSlippage={DEFAULT_ADD_IN_RANGE_SLIPPAGE_TOLERANCE}
            showBackLink={!hasExistingPosition}
          >
            {!hasExistingPosition && (
              <Row justifyContent="flex-end" style={{ width: 'fit-content', minWidth: 'fit-content' }}>
                <MediumOnly>
                  <ButtonText onClick={clearAll} margin="0 15px 0 0">
                    <ThemedText.Blue fontSize="12px">
                      <Trans>Clear All</Trans>
                    </ThemedText.Blue>
                  </ButtonText>
                </MediumOnly>
                {baseCurrency && quoteCurrency ? (
                  <RateToggle
                    currencyA={baseCurrency}
                    currencyB={quoteCurrency}
                    handleRateToggle={() => {
                      if (!ticksAtLimit[Bound.LOWER] && !ticksAtLimit[Bound.UPPER]) {
                        onLeftRangeInput((invertPrice ? priceLower : priceUpper?.invert())?.toSignificant(6) ?? '')
                        onRightRangeInput((invertPrice ? priceUpper : priceLower?.invert())?.toSignificant(6) ?? '')
                        onFieldAInput(formattedAmounts[Field.CURRENCY_B] ?? '')
                      }
                      history.push(
                        `/settings/${currencyIdB as string}/${currencyIdA as string}${feeAmount ? '/' + feeAmount : ''}`
                      )
                    }}
                  />
                ) : null}
              </Row>
            )}
          </VaultSettingsTab>
          <Wrapper>
            <ResponsiveTwoColumns wide={!hasExistingPosition}>
              <AutoColumn gap="lg">
                {!hasExistingPosition && (
                  <>
                    <AutoColumn gap="md">
                      <RowBetween paddingBottom="20px">
                        <ThemedText.Label>
                          <Trans>Select Pair</Trans>
                        </ThemedText.Label>
                      </RowBetween>
                      <RowBetween>
                        <CurrencyDropdown
                          value={formattedAmounts[Field.CURRENCY_A]}
                          onUserInput={onFieldAInput}
                          hideInput={true}
                          onMax={() => {
                            onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
                          }}
                          onCurrencySelect={handleCurrencyASelect}
                          showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
                          currency={currencies[Field.CURRENCY_A] ?? null}
                          id="add-liquidity-input-tokena"
                          showCommonBases
                        />

                        <div style={{ width: '12px' }} />

                        <CurrencyDropdown
                          value={formattedAmounts[Field.CURRENCY_B]}
                          hideInput={true}
                          onUserInput={onFieldBInput}
                          onCurrencySelect={handleCurrencyBSelect}
                          onMax={() => {
                            onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
                          }}
                          showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
                          currency={currencies[Field.CURRENCY_B] ?? null}
                          id="add-liquidity-input-tokenb"
                          showCommonBases
                        />
                      </RowBetween>

                      <FeeSelector
                        disabled={!quoteCurrency || !baseCurrency}
                        feeAmount={feeAmount}
                        handleFeePoolSelect={handleFeePoolSelect}
                        currencyA={baseCurrency ?? undefined}
                        currencyB={quoteCurrency ?? undefined}
                      />
                    </AutoColumn>{' '}
                  </>
                )}
                {hasExistingPosition && existingPosition && (
                  <PositionPreview
                    position={existingPosition}
                    title={<Trans>Selected Range</Trans>}
                    inRange={!outOfRange}
                    ticksAtLimit={ticksAtLimit}
                  />
                )}
              </AutoColumn>
              <DynamicSection gap="md">
              <ThemedText.Label>
                  <Trans>TWAP Period</Trans>
                </ThemedText.Label>
                <CurrencyInputPanel
                  value={twapPeriod}
                  onUserInput={onFieldTwapInput}
                  id="twap-period"
                />
                <ButtonSecondary
                  padding="15px"
                  $borderRadius="20px"
                  width="auto"
                  onClick={() => {
                    twapPeriodCallback()
                  }}
                >Set TWAP Period</ButtonSecondary>

                <ThemedText.Label>
                  <Trans>Max Deposit per Token(USD)</Trans>
                </ThemedText.Label>
                <CurrencyInputPanel
                  value={maxDeposit0}
                  onUserInput={onFieldMaxDeposit0Input}
                  id="max-deposit0"
                />
                <CurrencyInputPanel
                  value={maxDeposit1}
                  onUserInput={onFieldMaxDeposit1Input}
                  id="max-deposit1"
                />
                <ButtonSecondary
                  padding="15px"
                  $borderRadius="20px"
                  width="auto"
                  onClick={() => {
                    maxDepositCallback()
                  }}
                >Change Max Deposit per Token</ButtonSecondary>



              </DynamicSection>

              {!hasExistingPosition ? (
                <>
                  <HideMedium>
                    <Buttons />
                  </HideMedium>
                  <RightContainer gap="lg">
                    <ThemedText.Label>
                      <Trans>Affiliate Fees Address</Trans>
                    </ThemedText.Label>
                    <AddressInputPanel
                      value={affiliate}
                      label=""
                      placeholder=""
                      onChange={onFieldAffiliateInput}
                      id="affiliate-fee-address"
                    />
                    <ButtonSecondary
                      padding="15px"
                      $borderRadius="20px"
                      width="auto"
                      onClick={() => {
                        affiliateCallback()
                      }}
                    >Set Affiliate Fees Address</ButtonSecondary>

                    <ThemedText.Label>
                      <Trans>Hysteresis(%)</Trans>
                    </ThemedText.Label>
                    <CurrencyInputPanel
                      value={hysteresis}
                      onUserInput={onFieldHysteresisInput}
                      id="Hysteresis"
                    />
                    <ButtonSecondary
                      padding="15px"
                      $borderRadius="20px"
                      width="auto"
                      onClick={() => {
                        hysteresisCallback()
                      }}
                    >Set Hysteresis</ButtonSecondary>

                    <ThemedText.Label>
                      <Trans>Max Total Supply(USD)</Trans>
                    </ThemedText.Label>
                    <CurrencyInputPanel
                      value={maxTotalSupply}
                      onUserInput={onFieldMaxTotalSupplyInput}
                      id="max-total-supply"
                    />
                    <ButtonSecondary
                      padding="15px"
                      $borderRadius="20px"
                      width="auto"
                      onClick={() => {
                        maxTotalSupplyCallback()
                      }}
                    >Change Max Total Supply</ButtonSecondary>


                    <MediumOnly>
                      <Buttons />
                    </MediumOnly>
                  </RightContainer>
                </>
              ) : (
                <Buttons />
              )}
            </ResponsiveTwoColumns>
          </Wrapper>
        </PageWrapper>
        {addIsUnsupported && (
          <UnsupportedCurrencyFooter
            show={addIsUnsupported}
            currencies={[currencies.CURRENCY_A, currencies.CURRENCY_B]}
          />
        )}
      </ScrollablePage>
      <SwitchLocaleLink />
    </>
  )
}
