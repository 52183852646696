import { Trans } from '@lingui/macro'
import { Fraction } from '@uniswap/sdk-core'
import JSBI from 'jsbi'

import { useCurrency, useToken } from '../../hooks/Tokens'
import {
  AddLiquidityV3PoolTransactionInfo,
  AffiliateTransactionInfo,
  ApproveTransactionInfo,
  CollectFeesTransactionInfo,
  CreateV3PoolTransactionInfo,
  DepositLiquidityStakingTransactionInfo,
  HysteresisTransactionInfo,
  MaxDepositTransactionInfo,
  MaxTotalSupplyTransactionInfo,
  RebalanceTransactionInfo,
  RemoveLiquidityV3TransactionInfo,
  SimpleRebalanceTransactionInfo,
  SimpleRebalanceV2TransactionInfo,
  TransactionInfo,
  TransactionType,
  TwapPeriodTransactionInfo,
  WithdrawLiquidityStakingTransactionInfo,
  WrapTransactionInfo,
} from '../../state/transactions/actions'

function formatAmount(amountRaw: string, decimals: number, sigFigs: number): string {
  return new Fraction(amountRaw, JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(decimals))).toSignificant(sigFigs)
}

function FormattedCurrencyAmount({
  rawAmount,
  symbol,
  decimals,
  sigFigs,
}: {
  rawAmount: string
  symbol: string
  decimals: number
  sigFigs: number
}) {
  return (
    <>
      {formatAmount(rawAmount, decimals, sigFigs)} {symbol}
    </>
  )
}

function FormattedCurrencyAmountManaged({
  rawAmount,
  currencyId,
  sigFigs = 6,
}: {
  rawAmount: string
  currencyId: string
  sigFigs: number
}) {
  const currency = useCurrency(currencyId)
  return currency ? (
    <FormattedCurrencyAmount
      rawAmount={rawAmount}
      decimals={currency.decimals}
      sigFigs={sigFigs}
      symbol={currency.symbol ?? '???'}
    />
  ) : null
}

function ApprovalSummary({ info }: { info: ApproveTransactionInfo }) {
  const token = useToken(info.tokenAddress)

  return <Trans>Approve {token?.symbol}</Trans>
}

function WrapSummary({ info: { currencyAmountRaw, unwrapped } }: { info: WrapTransactionInfo }) {
  if (unwrapped) {
    return (
      <Trans>
        Unwrap <FormattedCurrencyAmount rawAmount={currencyAmountRaw} symbol={'WETH'} decimals={18} sigFigs={6} /> to
        ETH
      </Trans>
    )
  } else {
    return (
      <Trans>
        Wrap <FormattedCurrencyAmount rawAmount={currencyAmountRaw} symbol={'ETH'} decimals={18} sigFigs={6} /> to WETH
      </Trans>
    )
  }
}

function DepositLiquidityStakingSummary(_: { info: DepositLiquidityStakingTransactionInfo }) {
  // not worth rendering the tokens since you can should no longer deposit liquidity in the staking contracts
  // todo: deprecate and delete the code paths that allow this, show user more information
  return <Trans>Deposit liquidity</Trans>
}

function WithdrawLiquidityStakingSummary(_: { info: WithdrawLiquidityStakingTransactionInfo }) {
  return <Trans>Withdraw deposited liquidity</Trans>
}

function RebalanceSummary({
  info: { vaultAddress, baseLowerTick, baseUpperTick, limitLowerTick, limitUpperTick, swap },
}: {
  info: RebalanceTransactionInfo
}) {
  return (
    <Trans>
      Rebalance Vault ({vaultAddress}) with Base Position ({baseLowerTick}-{baseUpperTick}) and Limit Position (
      {limitLowerTick}-{limitUpperTick}) and Swap ({swap})
    </Trans>
  )
}

function SimpleRebalanceSummary({
  info: { vaultAddress, baseLoPct, concentrateLimitPct, concentrateThresholdPct },
}: {
  info: SimpleRebalanceTransactionInfo
}) {
  return (
    <Trans>
      Simplified Rebalance V1 Vault ({vaultAddress}) with params: baseLoPct: {baseLoPct}, concentrateLimitPct: {concentrateLimitPct}, concentrateThresholdPct: {concentrateThresholdPct})
    </Trans>
  )
}

function SimpleRebalanceV2Summary({
  info: { vaultAddress, baseLoPct, limitUpPct, limitReserveThresholdPct, healthThresholdPct },
}: {
  info: SimpleRebalanceV2TransactionInfo
}) {
  return (
    <Trans>
      Simplified Rebalance V2 Vault ({vaultAddress}) with params: baseLoPct: {baseLoPct}, limitUpPct: {limitUpPct}, limitReserveThresholdPct: {limitReserveThresholdPct}, healthThresholdPct: {healthThresholdPct})
    </Trans>
  )
}

function TwapPeriodSummary({ info: { vaultAddress, twapPeriodValue } }: { info: TwapPeriodTransactionInfo }) {
  return (
    <Trans>
      Set TWAP Period for Vault ({vaultAddress}) to {twapPeriodValue}
    </Trans>
  )
}

function AffiliateSummary({ info: { vaultAddress, affiliateValue } }: { info: AffiliateTransactionInfo }) {
  return (
    <Trans>
      Set Affiliate Fee Address for Vault ({vaultAddress}) to {affiliateValue}
    </Trans>
  )
}

function HysteresisSummary({ info: { vaultAddress, hysteresisValue } }: { info: HysteresisTransactionInfo }) {
  return (
    <Trans>
      Set Hysteresis for Vault ({vaultAddress}) to {hysteresisValue}
    </Trans>
  )
}

function MaxTotalSupplySummary({
  info: { vaultAddress, maxTotalSupplyValue },
}: {
  info: MaxTotalSupplyTransactionInfo
}) {
  return (
    <Trans>
      Set MaxTotalSupply for Vault ({vaultAddress}) to {maxTotalSupplyValue}
    </Trans>
  )
}

function MaxDepositSummary({
  info: { vaultAddress, maxDeposit0Value, maxDeposit1Value },
}: {
  info: MaxDepositTransactionInfo
}) {
  return (
    <Trans>
      Set Max Deposit per Token for Vault ({vaultAddress}) to {maxDeposit0Value} and {maxDeposit1Value}
    </Trans>
  )
}

function CreateV3PoolSummary({ info: { quoteCurrencyId, baseCurrencyId } }: { info: CreateV3PoolTransactionInfo }) {
  const baseCurrency = useCurrency(baseCurrencyId)
  const quoteCurrency = useCurrency(quoteCurrencyId)

  return (
    <Trans>
      Create {baseCurrency?.symbol}/{quoteCurrency?.symbol} V3 pool
    </Trans>
  )
}

function CollectFeesSummary({ info: { currencyId0, currencyId1 } }: { info: CollectFeesTransactionInfo }) {
  const currency0 = useCurrency(currencyId0)
  const currency1 = useCurrency(currencyId1)

  return (
    <Trans>
      Collect {currency0?.symbol}/{currency1?.symbol} fees
    </Trans>
  )
}

function RemoveLiquidityV3Summary({
  info: { baseCurrencyId, quoteCurrencyId, expectedAmountBaseRaw, expectedAmountQuoteRaw },
}: {
  info: RemoveLiquidityV3TransactionInfo
}) {
  return (
    <Trans>
      Remove{' '}
      <FormattedCurrencyAmountManaged rawAmount={expectedAmountBaseRaw} currencyId={baseCurrencyId} sigFigs={3} /> and{' '}
      <FormattedCurrencyAmountManaged rawAmount={expectedAmountQuoteRaw} currencyId={quoteCurrencyId} sigFigs={3} />
    </Trans>
  )
}

function AddLiquidityV3PoolSummary({
  info: { createPool, quoteCurrencyId, baseCurrencyId },
}: {
  info: AddLiquidityV3PoolTransactionInfo
}) {
  const baseCurrency = useCurrency(baseCurrencyId)
  const quoteCurrency = useCurrency(quoteCurrencyId)

  return createPool ? (
    <Trans>
      Create pool and add {baseCurrency?.symbol}/{quoteCurrency?.symbol} V3 liquidity
    </Trans>
  ) : (
    <Trans>
      Add {baseCurrency?.symbol}/{quoteCurrency?.symbol} V3 liquidity
    </Trans>
  )
}

export function TransactionSummary({ info }: { info: TransactionInfo }) {
  switch (info.type) {
    case TransactionType.ADD_LIQUIDITY_V3_POOL:
      return <AddLiquidityV3PoolSummary info={info} />

    case TransactionType.DEPOSIT_LIQUIDITY_STAKING:
      return <DepositLiquidityStakingSummary info={info} />

    case TransactionType.WITHDRAW_LIQUIDITY_STAKING:
      return <WithdrawLiquidityStakingSummary info={info} />

    case TransactionType.APPROVAL:
      return <ApprovalSummary info={info} />

    case TransactionType.REBALANCE:
      return <RebalanceSummary info={info} />

    case TransactionType.SIMPLE_REBALANCE:
      return <SimpleRebalanceSummary info={info} />

    case TransactionType.SIMPLE_REBALANCE_V2:
      return <SimpleRebalanceV2Summary info={info} />

    case TransactionType.TWAP_PERIOD:
      return <TwapPeriodSummary info={info} />

    case TransactionType.AFFILIATE:
      return <AffiliateSummary info={info} />

    case TransactionType.HYSTERESIS:
      return <HysteresisSummary info={info} />

    case TransactionType.MAX_TOTAL_SUPPLY:
      return <MaxTotalSupplySummary info={info} />

    case TransactionType.MAX_DEPOSIT:
      return <MaxDepositSummary info={info} />

    case TransactionType.WRAP:
      return <WrapSummary info={info} />

    case TransactionType.CREATE_V3_POOL:
      return <CreateV3PoolSummary info={info} />

    case TransactionType.COLLECT_FEES:
      return <CollectFeesSummary info={info} />

    case TransactionType.REMOVE_LIQUIDITY_V3:
      return <RemoveLiquidityV3Summary info={info} />
  }
}
