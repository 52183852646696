import { BigNumber } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import { useCallback, useMemo } from 'react'

import { TransactionType } from '../state/transactions/actions'
import { useHasPendingAffiliate, useTransactionAdder } from '../state/transactions/hooks'
import { calculateGasMargin } from '../utils/calculateGasMargin'
import { useVaultContract } from './useContract'
import { useActiveWeb3React } from './web3'

export enum AffiliateState {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
}

// returns a variable indicating the state of the affiliate setting and a function which sets affiliate if necessary or early returns
export function useAffiliateCallback(vaultAddress?: string, affiliateInput?: string): [() => Promise<void>] {
  const { chainId } = useActiveWeb3React()
  const pendingAffiliate = useHasPendingAffiliate(vaultAddress)

  const affiliateState: AffiliateState = useMemo(() => {
    return pendingAffiliate ? AffiliateState.PENDING : AffiliateState.IDLE
  }, [pendingAffiliate])

  const vaultContract = useVaultContract(vaultAddress)
  const addTransaction = useTransactionAdder()

  const affiliate = useCallback(async (): Promise<void> => {
    if (affiliateState !== AffiliateState.IDLE) {
      console.error('affiliate setting was called unnecessarily')
      return
    }
    if (!chainId) {
      console.error('no chainId')
      return
    }
    if (!vaultAddress) {
      console.error('no vault')
      return
    }
    if (!vaultContract) {
      console.error('vaultContract is null')
      return
    }
    if (!affiliateInput) {
      console.error('missing affilient fee address value')
    }

    const affiliateInputProper = affiliateInput ?? ''

    return vaultContract
      .setAffiliate(affiliateInputProper, {
        gasLimit: calculateGasMargin(BigNumber.from('160000')),
      })
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          type: TransactionType.AFFILIATE,
          vaultAddress,
          affiliateValue: affiliateInputProper,
        })
      })
      .catch((error: Error) => {
        console.debug('Failed to set affiliate fee address: ', error)
        throw error
      })
  }, [affiliateState, affiliateInput, chainId, vaultAddress, vaultContract, addTransaction])

  return [affiliate]
}
