import { createAction } from '@reduxjs/toolkit'

export interface SerializableTransactionReceipt {
  to: string
  from: string
  contractAddress: string
  transactionIndex: number
  blockHash: string
  transactionHash: string
  blockNumber: number
  status?: number
}

/**
 * Be careful adding to this enum, always assign a unique value (typescript will not prevent duplicate values).
 * These values is persisted in state and if you change the value it will cause errors
 */
export enum TransactionType {
  APPROVAL = 0,
  DEPOSIT_LIQUIDITY_STAKING = 2,
  WITHDRAW_LIQUIDITY_STAKING = 3,
  WRAP = 7,
  CREATE_V3_POOL = 8,
  ADD_LIQUIDITY_V3_POOL = 9,
  COLLECT_FEES = 12,
  REMOVE_LIQUIDITY_V3 = 13,
  REBALANCE = 20,
  SIMPLE_REBALANCE = 25,
  SIMPLE_REBALANCE_V2 = 26,
  TWAP_PERIOD = 30,
  HYSTERESIS = 31,
  MAX_TOTAL_SUPPLY = 32,
  AFFILIATE = 33,
  MAX_DEPOSIT = 34,
}

export interface BaseTransactionInfo {
  type: TransactionType
}

export interface ApproveTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.APPROVAL
  tokenAddress: string
  spender: string
}

export interface DepositLiquidityStakingTransactionInfo {
  type: TransactionType.DEPOSIT_LIQUIDITY_STAKING
  token0Address: string
  token1Address: string
}

export interface WithdrawLiquidityStakingTransactionInfo {
  type: TransactionType.WITHDRAW_LIQUIDITY_STAKING
  token0Address: string
  token1Address: string
}

export interface WrapTransactionInfo {
  type: TransactionType.WRAP
  unwrapped: boolean
  currencyAmountRaw: string
}

export interface CreateV3PoolTransactionInfo {
  type: TransactionType.CREATE_V3_POOL
  baseCurrencyId: string
  quoteCurrencyId: string
}

export interface AddLiquidityV3PoolTransactionInfo {
  type: TransactionType.ADD_LIQUIDITY_V3_POOL
  createPool: boolean
  baseCurrencyId: string
  quoteCurrencyId: string
  feeAmount: number
  expectedAmountBaseRaw: string
  expectedAmountQuoteRaw: string
}

export interface CollectFeesTransactionInfo {
  type: TransactionType.COLLECT_FEES
  currencyId0: string
  currencyId1: string
}

export interface RemoveLiquidityV3TransactionInfo {
  type: TransactionType.REMOVE_LIQUIDITY_V3
  baseCurrencyId: string
  quoteCurrencyId: string
  expectedAmountBaseRaw: string
  expectedAmountQuoteRaw: string
}

export interface RebalanceTransactionInfo {
  type: TransactionType.REBALANCE
  vaultAddress: string
  baseLowerTick: number
  baseUpperTick: number
  limitLowerTick: number
  limitUpperTick: number
  swap: string
}

export interface SimpleRebalanceTransactionInfo {
  type: TransactionType.SIMPLE_REBALANCE
  vaultAddress: string
  baseLoPct: number
  concentrateLimitPct: number
  concentrateThresholdPct: number
}

export interface SimpleRebalanceV2TransactionInfo {
  type: TransactionType.SIMPLE_REBALANCE_V2
  vaultAddress: string
  baseLoPct: number
  limitUpPct: number
  limitReserveThresholdPct: number
  healthThresholdPct: number
}

export interface TwapPeriodTransactionInfo {
  type: TransactionType.TWAP_PERIOD
  vaultAddress: string
  twapPeriodValue: string
}

export interface AffiliateTransactionInfo {
  type: TransactionType.AFFILIATE
  vaultAddress: string
  affiliateValue: string
}

export interface HysteresisTransactionInfo {
  type: TransactionType.HYSTERESIS
  vaultAddress: string
  hysteresisValue: string
}

export interface MaxTotalSupplyTransactionInfo {
  type: TransactionType.MAX_TOTAL_SUPPLY
  vaultAddress: string
  maxTotalSupplyValue: string
}

export interface MaxDepositTransactionInfo {
  type: TransactionType.MAX_DEPOSIT
  vaultAddress: string
  maxDeposit0Value: string
  maxDeposit1Value: string
}

export type TransactionInfo =
  | AffiliateTransactionInfo
  | ApproveTransactionInfo
  | DepositLiquidityStakingTransactionInfo
  | WithdrawLiquidityStakingTransactionInfo
  | WrapTransactionInfo
  | CreateV3PoolTransactionInfo
  | AddLiquidityV3PoolTransactionInfo
  | CollectFeesTransactionInfo
  | RemoveLiquidityV3TransactionInfo
  | RebalanceTransactionInfo
  | SimpleRebalanceTransactionInfo
  | SimpleRebalanceV2TransactionInfo
  | TwapPeriodTransactionInfo
  | HysteresisTransactionInfo
  | MaxTotalSupplyTransactionInfo
  | MaxDepositTransactionInfo

export const addTransaction = createAction<{
  chainId: number
  hash: string
  from: string
  info: TransactionInfo
}>('transactions/addTransaction')
export const clearAllTransactions = createAction<{ chainId: number }>('transactions/clearAllTransactions')
export const finalizeTransaction = createAction<{
  chainId: number
  hash: string
  receipt: SerializableTransactionReceipt
}>('transactions/finalizeTransaction')
export const checkedTransaction = createAction<{
  chainId: number
  hash: string
  blockNumber: number
}>('transactions/checkedTransaction')
