/* eslint-disable prettier/prettier */
import { BigNumber } from '@ethersproject/bignumber';
import { Trans } from '@lingui/macro';
import { Currency, CurrencyAmount, Percent } from '@uniswap/sdk-core';
import { FeeAmount } from '@uniswap/v3-sdk';
import axios from 'axios';
import DowntimeWarning from 'components/DowntimeWarning';
import LeftRangeButton from 'components/RangeSelector/LeftRangeButton';
import RightRangeButton from 'components/RangeSelector/RightRangeButton';
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter';
import VaultStatsCard from 'components/VaultStatsCard';
import { useVaultContract } from 'hooks/useContract';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AlertTriangle } from 'react-feather';
import { RouteComponentProps } from 'react-router-dom';
import { Text } from 'rebass';
import {
  useRangeHopCallbacks,
  useV3DerivedMintInfo,
  useV3MintActionHandlers,
  useV3MintState
} from 'state/mint/v3/hooks';
import { ThemeContext } from 'styled-components/macro';
import isBaseCurrencyBaseToken from 'utils/isBaseCurrencyBaseToken';

import {
  ButtonError,
  ButtonLight,
  ButtonPrimary,
  ButtonSecondary,
  ButtonText,
  ButtonYellow
} from '../../components/Button';
import { BlueCard, OutlineCard, YellowCard } from '../../components/Card';
import { AutoColumn } from '../../components/Column';
import FeeSelector from '../../components/FeeSelector';
import HoverInlineText from '../../components/HoverInlineText';
import LiquidityChartRangeInput from '../../components/LiquidityChartRangeInput';
import { RebalanceTab } from '../../components/NavigationTabs';
import { PositionPreview } from '../../components/PositionPreview';
import RangeSelector from '../../components/RangeSelector';
// import PresetsButtons from '../../components/RangeSelector/PresetsButtons'
import RateToggle from '../../components/RateToggle';
import Row, { AutoRow, RowBetween, RowFixed } from '../../components/Row';
import { SwitchLocaleLink } from '../../components/SwitchLocaleLink';
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModal';
import { WETH9_EXTENDED } from '../../constants/tokens';
import { BNtoNumberWithoutDecimals, getVault, getVaultToken, VaultStats } from '../../constants/vaults';
import { useCurrency } from '../../hooks/Tokens';
import { useDerivedPositionInfo } from '../../hooks/useDerivedPositionInfo';
import { useIsSwapUnsupported } from '../../hooks/useIsSwapUnsupported';
import { RebalanceState, useRebalanceCallback } from '../../hooks/useRebalanceCallback';
import { useV3PositionFromTokenId } from '../../hooks/useV3Positions';
import { useActiveWeb3React } from '../../hooks/web3';
import { useWalletModalToggle } from '../../state/application/hooks';
import { Bound, Field } from '../../state/mint/v3/actions';
import { useIsExpertMode } from '../../state/user/hooks';
import { ExternalLink, ThemedText } from '../../theme';
import { currencyId } from '../../utils/currencyId';
import { maxAmountSpend } from '../../utils/maxAmountSpend';
import { Dots } from '../Pool/styleds';
import { Review } from './Review';
import {
  CurrencyDropdown,
  DynamicSection,
  HideMedium,
  MediumOnly,
  PageWrapper,
  ResponsiveTwoColumns,
  RightContainer,
  ScrollablePage,
  StackedContainer,
  StackedItem,
  StyledInput,
  Wrapper
} from './styled';

const DEFAULT_ADD_IN_RANGE_SLIPPAGE_TOLERANCE = new Percent(50, 10_000);

export default function AddLiquidity({
  match: {
    params: { currencyIdA, currencyIdB, feeAmount: feeAmountFromUrl, tokenId }
  },
  history
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string; feeAmount?: string; tokenId?: string }>) {
  const { account, chainId } = useActiveWeb3React();
  const theme = useContext(ThemeContext);
  const toggleWalletModal = useWalletModalToggle(); // toggle wallet when disconnected
  const expertMode = useIsExpertMode();

  // check for existing position if tokenId in url
  const { position: existingPositionDetails, loading: positionLoading } = useV3PositionFromTokenId(
    tokenId ? BigNumber.from(tokenId) : undefined
  );
  const hasExistingPosition = !!existingPositionDetails && !positionLoading;
  const { position: existingPosition } = useDerivedPositionInfo(existingPositionDetails);

  // fee selection from url
  const feeAmount: FeeAmount | undefined =
    feeAmountFromUrl && Object.values(FeeAmount).includes(parseFloat(feeAmountFromUrl))
      ? parseFloat(feeAmountFromUrl)
      : undefined;

  // baseCurrency is the currency in the dropdown 1;
  // quoteCurrency is the currency in the dropdown 2
  const baseCurrency = useCurrency(currencyIdA);
  const currencyB = useCurrency(currencyIdB);
  // prevent an error if they input ETH/WETH
  const quoteCurrency =
    baseCurrency && currencyB && baseCurrency.wrapped.equals(currencyB.wrapped) ? undefined : currencyB;

  // mint state
  const { independentField, typedValue, startPriceTypedValue } = useV3MintState();

  const {
    pool,
    ticks,
    dependentField,
    price,
    pricesAtTicks,
    parsedAmounts,
    currencyBalances,
    // position,
    noLiquidity,
    currencies,
    // errorMessage,
    invalidPool,
    invalidRange,
    outOfRange,
    depositADisabled,
    depositBDisabled,
    invertPrice,
    ticksAtLimit
  } = useV3DerivedMintInfo(
    baseCurrency ?? undefined,
    quoteCurrency ?? undefined,
    feeAmount,
    baseCurrency ?? undefined,
    existingPosition
  );

  const [baseTickLower, setBaseTickLower] = useState<number>(0);
  const [baseTickUpper, setBaseTickUpper] = useState<number>(0);
  const [limitTickLower, setLimitTickLower] = useState<number>(0);
  const [limitTickUpper, setLimitTickUpper] = useState<number>(0);
  const [fixedMemberTokenInBase, setFixedMemberTokenInBase] = useState<number>(-1);
  const [showMemberTokenInBase, setShowMemberTokenInBase] = useState<boolean>(false);

  // oneToken should actually be called baseToken, as we have different kinds for HODL vaults now.
  const [vaultStats, setVaultStats] = useState<VaultStats>({
    memberTokenAmount: '0',
    memberTokenValue: 0,
    memberTokenPrice: 0,
    memberTokenRatio: 0,
    oneTokenAmount: '0',
    oneTokenValue: 0,
    totalValue: 0,
    vaultStrength: 0,
    memberTokenMarketCap: 0,
    sevenDaysChange: 0,
    currentTick: ''
  });

  const { onFieldAInput, onFieldBInput, onLeftRangeInput, onRightRangeInput, onStartPriceInput } =
    useV3MintActionHandlers(noLiquidity);

  const isValid = !invalidRange;

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  // capital efficiency warning
  const [showCapitalEfficiencyWarning, setShowCapitalEfficiencyWarning] = useState(false);

  useEffect(() => setShowCapitalEfficiencyWarning(false), [baseCurrency, quoteCurrency, feeAmount]);

  // txn values
  const [txHash, setTxHash] = useState<string>('');

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  };

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field])
      };
    },
    {}
  );

  const atMaxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0')
      };
    },
    {}
  );

  const vault =
    baseCurrency && quoteCurrency
      ? getVault(chainId, baseCurrency?.symbol, quoteCurrency?.symbol, feeAmount)
      : undefined;
  const vaultAddress = vault ? vault.address : undefined;
  const [rebalance, rebalanceCallback] = useRebalanceCallback(
    vault,
    vaultStats,
    baseTickLower,
    baseTickUpper,
    limitTickLower,
    limitTickUpper,
    '0'
  );

  const vaultContract = useVaultContract(vaultAddress);

  // should be: ifBaseCurrencyBaseToken (as opposed to memberToken)
  const isBaseCurrencyOneToken = isBaseCurrencyBaseToken(baseCurrency?.name, vault?.baseTokenName);

  const getVaultStats = async () => {
    if (!vaultContract) return;

    let currentTick: string;
    try {
      currentTick = (await vaultContract.currentTick()).toString();
    } catch (e) {
      throw e;
    }

    const token0 = await vaultContract.token0();
    const token1 = await vaultContract.token1();

    const allowToken0 = await vaultContract.allowToken0();

    const vaultToken0 = getVaultToken(token0);
    const vaultToken1 = getVaultToken(token1);

    const amounts = await vaultContract.getTotalAmounts();
    if (amounts && vaultToken0 && vaultToken1) {
      let memberTokenAmount = amounts.total0.toString();
      let oneTokenAmount = amounts.total1.toString();
      let memberTokenDecimals = vaultToken0.decimals;
      let oneTokenDecimals = vaultToken1.decimals;
      let memberTokenPriceAsStr = '0';
      let oneTokenPriceAsStr = '0';
      const switchTokens = !(isBaseCurrencyOneToken === invertPrice);
      // price of token in the other token
      const memberTokenPriceFromVault = price
        ? switchTokens
          ? price.invert().toSignificant(6)
          : price.toSignificant(6)
        : '0';
      const oneTokenPriceFromVault = price
        ? switchTokens
          ? price.toSignificant(6)
          : price.invert().toSignificant(6)
        : '0';
      let memberTokenMarketCap = 0;
      let sevenDaysChange = 0;
      let vaultStrength = 0;
      let memberToken = vaultToken1;
      let oneToken = vaultToken0;
      // if (vaultToken1.memberToken) {
      if (allowToken0) {
        memberTokenAmount = amounts.total1.toString();
        oneTokenAmount = amounts.total0.toString();
      } else {
        memberToken = vaultToken0;
        oneToken = vaultToken1;
        memberTokenAmount = amounts.total0.toString();
        oneTokenAmount = amounts.total1.toString();
      }
      memberTokenDecimals = memberToken.decimals;
      oneTokenDecimals = oneToken.decimals;

      // if memberToken is ICHI v2 use the price of legacy ICHI from CoinGecko
      const memberTokenAddressForCoinGecko =
        memberToken.address === '0x903bEF1736CDdf2A537176cf3C64579C3867A881'
          ? '0x111111517e4929D3dcbdfa7CCe55d30d4B6BC4d6'
          : memberToken.address;
      if (vaultToken0.coingeckoId !== '' || vaultToken1.coingeckoId !== '') {
        const response = await axios.get(
          `https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=${memberTokenAddressForCoinGecko.toLowerCase()}&vs_currencies=usd&include_24hr_change=true`
        );
        if (
          response &&
          response.data &&
          JSON.parse(JSON.stringify(response.data))[memberToken.address.toLowerCase()]?.usd
        ) {
          memberTokenPriceAsStr = JSON.parse(JSON.stringify(response.data))[
            memberTokenAddressForCoinGecko.toLowerCase()
          ].usd;
        }
        const additionalResponse =
          memberToken.coingeckoId !== ''
            ? await axios.get(
                `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${memberToken.coingeckoId}&sparkline=false&price_change_percentage=7d`
              )
            : undefined;
        if (additionalResponse && additionalResponse.data && JSON.stringify(additionalResponse.data) !== '{}') {
          memberTokenMarketCap = Number(JSON.parse(JSON.stringify(additionalResponse.data))[0].market_cap);
          sevenDaysChange = Number(
            JSON.parse(JSON.stringify(additionalResponse.data))[0].price_change_percentage_7d_in_currency
          );
        }
        const response1 = await axios.get(
          `https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=${oneToken.address.toLowerCase()}&vs_currencies=usd&include_24hr_change=true`
        );
        if (
          response1 &&
          response1.data &&
          JSON.parse(JSON.stringify(response1.data))[oneToken.address.toLowerCase()]?.usd
        ) {
          oneTokenPriceAsStr = JSON.parse(JSON.stringify(response1.data))[oneToken.address.toLowerCase()].usd;
        }
      }

      const memberTokenPrice =
        memberTokenPriceAsStr === '0'
          ? Number(memberTokenPriceFromVault) * Number(oneTokenPriceAsStr)
          : Number(memberTokenPriceAsStr);

      const oneTokenPrice =
        oneTokenPriceAsStr === '0'
          ? Number(oneTokenPriceFromVault) * Number(memberTokenPriceAsStr)
          : Number(oneTokenPriceAsStr);

      const oneTokenValue = BNtoNumberWithoutDecimals(oneTokenAmount, oneTokenDecimals) * oneTokenPrice;
      const memberTokenValue = BNtoNumberWithoutDecimals(memberTokenAmount, memberTokenDecimals) * memberTokenPrice;
      // total value in baseTokens
      const oneTokenValueFromVault = BNtoNumberWithoutDecimals(oneTokenAmount, oneTokenDecimals) * 1;
      const memberTokenValueFromVault =
        BNtoNumberWithoutDecimals(memberTokenAmount, memberTokenDecimals) * Number(memberTokenPriceFromVault);
      const totalValue = oneTokenValue + memberTokenValue;
      const memberTokenRatio = Number(
        ((memberTokenValueFromVault / (memberTokenValueFromVault + oneTokenValueFromVault)) * 100).toFixed(2)
      );
      vaultStrength = memberTokenMarketCap !== 0 ? (oneTokenValue / memberTokenMarketCap) * 100 : 0;
      const result: VaultStats = {
        memberTokenAmount,
        memberTokenValue,
        memberTokenPrice,
        memberTokenRatio,
        oneTokenAmount,
        oneTokenValue,
        totalValue,
        vaultStrength,
        memberTokenMarketCap,
        sevenDaysChange,
        currentTick
      };
      setVaultStats(result);
    }
  };

  const handleCurrencySelect = useCallback(
    (currencyNew: Currency, currencyIdOther?: string): (string | undefined)[] => {
      const currencyIdNew = currencyId(currencyNew);

      if (currencyIdNew === currencyIdOther) {
        // not ideal, but for now clobber the other if the currency ids are equal
        return [currencyIdNew, undefined];
      } else {
        // prevent weth + eth
        const isETHOrWETHNew =
          currencyIdNew === 'ETH' || (chainId !== undefined && currencyIdNew === WETH9_EXTENDED[chainId]?.address);
        const isETHOrWETHOther =
          currencyIdOther !== undefined &&
          (currencyIdOther === 'ETH' ||
            (chainId !== undefined && currencyIdOther === WETH9_EXTENDED[chainId]?.address));

        if (isETHOrWETHNew && isETHOrWETHOther) {
          return [currencyIdNew, undefined];
        } else {
          return [currencyIdNew, currencyIdOther];
        }
      }
    },
    [chainId]
  );

  const handleCurrencyASelect = useCallback(
    (currencyANew: Currency) => {
      const [idA, idB] = handleCurrencySelect(currencyANew, currencyIdB);
      if (idB === undefined) {
        history.push(`/rebalance/${idA}`);
      } else {
        history.push(`/rebalance/${idA}/${idB}`);
      }
    },
    [handleCurrencySelect, currencyIdB, history]
  );

  const handleCurrencyBSelect = useCallback(
    (currencyBNew: Currency) => {
      const [idB, idA] = handleCurrencySelect(currencyBNew, currencyIdA);
      if (idA === undefined) {
        history.push(`/rebalance/${idB}`);
      } else {
        history.push(`/rebalance/${idA}/${idB}`);
      }
    },
    [handleCurrencySelect, currencyIdA, history]
  );

  const handleFeePoolSelect = useCallback(
    (newFeeAmount: FeeAmount) => {
      onLeftRangeInput('');
      onRightRangeInput('');
      history.push(`/rebalance/${currencyIdA}/${currencyIdB}/${newFeeAmount}`);
    },
    [currencyIdA, currencyIdB, history, onLeftRangeInput, onRightRangeInput]
  );

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false);
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onFieldAInput('');
      // dont jump to pool page if creating
      history.push('/pool');
    }
    setTxHash('');
  }, [history, onFieldAInput, txHash]);

  const addIsUnsupported = useIsSwapUnsupported(currencies?.CURRENCY_A, currencies?.CURRENCY_B);

  const clearAll = useCallback(() => {
    onFieldAInput('');
    onFieldBInput('');
    onLeftRangeInput('');
    onRightRangeInput('');
    history.push(`/rebalance`);
  }, [history, onFieldAInput, onFieldBInput, onLeftRangeInput, onRightRangeInput]);

  // get value and prices at ticks
  const { [Bound.LOWER]: tickLower, [Bound.UPPER]: tickUpper } = ticks;
  const { [Bound.LOWER]: priceLower, [Bound.UPPER]: priceUpper } = pricesAtTicks;

  const {
    getDecrementLower,
    getIncrementLower,
    getDecrementUpper,
    getIncrementUpper,
    getSetFullRange,
    getSetLeftRangeToMin,
    getSetRightRangeToMax
  } = useRangeHopCallbacks(
    baseCurrency ?? undefined,
    quoteCurrency ?? undefined,
    feeAmount,
    tickLower,
    tickUpper,
    pool
  );

  const pendingText = `Supplying ${!depositADisabled ? parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) : ''} ${
    !depositADisabled ? currencies[Field.CURRENCY_A]?.symbol : ''
  } ${!outOfRange ? 'and' : ''} ${!depositBDisabled ? parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) : ''} ${
    !depositBDisabled ? currencies[Field.CURRENCY_B]?.symbol : ''
  }`;
  // const memberInBase = isBaseCurrencyOneToken ? formattedAmounts[Field.CURRENCY_B] : formattedAmounts[Field.CURRENCY_A]
  // const memberInBaseUSD = Number(memberInBase) * vaultStats.memberTokenPrice
  // const memberInBasePercent = ((memberInBaseUSD / vaultStats.memberTokenValue) * 100).toFixed(2)
  let memberInBasePercent = vault?.isInverted
    ? (
        ((Number(ticks.UPPER) - Number(vaultStats.currentTick)) / (Number(ticks.UPPER) - Number(ticks.LOWER))) *
        100
      ).toFixed(2)
    : (
        ((Number(ticks.LOWER) - Number(vaultStats.currentTick)) / (Number(ticks.LOWER) - Number(ticks.UPPER))) *
        100
      ).toFixed(2);
  memberInBasePercent = Number(memberInBasePercent) < 0 ? '0' : memberInBasePercent;
  const memberInBaseUSD = (Number(memberInBasePercent) * Number(vaultStats.oneTokenValue)) / 100;

  const rebalanceDisabled =
    rebalance === RebalanceState.PENDING ||
    baseTickLower === 0 ||
    baseTickUpper === 0 ||
    limitTickLower === 0 ||
    limitTickUpper === 0 ||
    (baseTickLower === limitTickLower && baseTickUpper === limitTickUpper);

  const Buttons = () =>
    addIsUnsupported ? (
      <ButtonPrimary disabled={true} $borderRadius="12px" padding={'12px'}>
        <ThemedText.Main mb="4px">
          <Trans>Unsupported Asset</Trans>
        </ThemedText.Main>
      </ButtonPrimary>
    ) : !account ? (
      <ButtonLight onClick={toggleWalletModal} $borderRadius="12px" padding={'12px'}>
        <Trans>Connect Wallet</Trans>
      </ButtonLight>
    ) : (
      <AutoColumn gap={'md'}>
        {(rebalance === RebalanceState.PENDING || rebalance === RebalanceState.IDLE) && isValid && (
          <RowBetween>
            <ButtonPrimary onClick={rebalanceCallback} disabled={rebalanceDisabled} width={'100%'}>
              {rebalance === RebalanceState.PENDING ? (
                <Dots>
                  <Trans>Rebalancing</Trans>
                </Dots>
              ) : (
                <Trans>Rebalance</Trans>
              )}
            </ButtonPrimary>
          </RowBetween>
        )}
        {!expertMode && (
          <ButtonError
            onClick={() => {
              setShowConfirm(true);
            }}
            disabled={rebalanceDisabled}
          >
            <Text fontWeight={500}>
              <Trans>Preview</Trans>
            </Text>
          </ButtonError>
        )}
      </AutoColumn>
    );
  return (
    <>
      <ScrollablePage>
        <DowntimeWarning />
        <TransactionConfirmationModal
          isOpen={showConfirm}
          onDismiss={handleDismissConfirmation}
          attemptingTxn={rebalance === RebalanceState.PENDING}
          hash={txHash}
          content={() => (
            <ConfirmationModalContent
              title={<Trans>Rebalance Parameters</Trans>}
              onDismiss={handleDismissConfirmation}
              topContent={() => (
                <Review
                  vaultAddress={vaultAddress}
                  adminAddress={vault?.admin}
                  safeAddress={vault?.safe}
                  currentTick={vaultStats.currentTick}
                  baseTickLower={baseTickLower}
                  baseTickUpper={baseTickUpper}
                  limitTickLower={limitTickLower}
                  limitTickUpper={limitTickUpper}
                  swap={'0'}
                />
              )}
              bottomContent={() => (
                <ButtonPrimary style={{ marginTop: '1rem' }} onClick={rebalanceCallback}>
                  <Text fontWeight={500} fontSize={20}>
                    <Trans>Rebalance</Trans>
                  </Text>
                </ButtonPrimary>
              )}
            />
          )}
          pendingText={pendingText}
        />
        <PageWrapper wide={!hasExistingPosition}>
          <RebalanceTab
            creating={false}
            adding={true}
            positionID={tokenId}
            defaultSlippage={DEFAULT_ADD_IN_RANGE_SLIPPAGE_TOLERANCE}
            showBackLink={!hasExistingPosition}
          >
            {!hasExistingPosition && (
              <Row justifyContent="flex-end" style={{ width: 'fit-content', minWidth: 'fit-content' }}>
                <MediumOnly>
                  <ButtonText onClick={clearAll} margin="0 15px 0 0">
                    <ThemedText.Blue fontSize="12px">
                      <Trans>Clear All</Trans>
                    </ThemedText.Blue>
                  </ButtonText>
                </MediumOnly>
                {baseCurrency && quoteCurrency ? (
                  <RateToggle
                    currencyA={baseCurrency}
                    currencyB={quoteCurrency}
                    handleRateToggle={() => {
                      if (!ticksAtLimit[Bound.LOWER] && !ticksAtLimit[Bound.UPPER]) {
                        onLeftRangeInput((invertPrice ? priceLower : priceUpper?.invert())?.toSignificant(6) ?? '');
                        onRightRangeInput((invertPrice ? priceUpper : priceLower?.invert())?.toSignificant(6) ?? '');
                        onFieldAInput(formattedAmounts[Field.CURRENCY_B] ?? '');
                      }
                      history.push(
                        `/rebalance/${currencyIdB as string}/${currencyIdA as string}${
                          feeAmount ? '/' + feeAmount : ''
                        }`
                      );
                    }}
                  />
                ) : null}
              </Row>
            )}
          </RebalanceTab>
          <Wrapper>
            <ResponsiveTwoColumns wide={!hasExistingPosition}>
              <AutoColumn gap="lg">
                {!hasExistingPosition && (
                  <>
                    <AutoColumn gap="md">
                      <RowBetween paddingBottom="20px">
                        <ThemedText.Label>
                          <Trans>Select Pair</Trans>
                        </ThemedText.Label>
                      </RowBetween>
                      <RowBetween>
                        <CurrencyDropdown
                          value={formattedAmounts[Field.CURRENCY_A]}
                          onUserInput={onFieldAInput}
                          hideInput={true}
                          onMax={() => {
                            onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '');
                          }}
                          onCurrencySelect={handleCurrencyASelect}
                          showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
                          currency={currencies[Field.CURRENCY_A] ?? null}
                          id="add-liquidity-input-tokena"
                          showCommonBases
                        />

                        <div style={{ width: '12px' }} />

                        <CurrencyDropdown
                          value={formattedAmounts[Field.CURRENCY_B]}
                          hideInput={true}
                          onUserInput={onFieldBInput}
                          onCurrencySelect={handleCurrencyBSelect}
                          onMax={() => {
                            onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '');
                          }}
                          showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
                          currency={currencies[Field.CURRENCY_B] ?? null}
                          id="add-liquidity-input-tokenb"
                          showCommonBases
                        />
                      </RowBetween>

                      <FeeSelector
                        disabled={!quoteCurrency || !baseCurrency}
                        feeAmount={feeAmount}
                        handleFeePoolSelect={handleFeePoolSelect}
                        currencyA={baseCurrency ?? undefined}
                        currencyB={quoteCurrency ?? undefined}
                      />
                    </AutoColumn>{' '}
                  </>
                )}
                {hasExistingPosition && existingPosition && (
                  <PositionPreview
                    position={existingPosition}
                    title={<Trans>Selected Range</Trans>}
                    inRange={!outOfRange}
                    ticksAtLimit={ticksAtLimit}
                  />
                )}
              </AutoColumn>
              <DynamicSection gap="md">
                <ThemedText.Label>
                  <Trans>Base Position</Trans>
                </ThemedText.Label>
                <ThemedText.Main>
                  <RowBetween>
                    <span>Current: {tickLower ? tickLower : 0}</span>
                    <span>{baseTickLower !== 0 ? `Set: ${baseTickLower}` : ''}</span>
                  </RowBetween>
                  <RowBetween>
                    <span>Current: {ticks.UPPER ? ticks.UPPER : 0}</span>
                    <span>{baseTickUpper !== 0 ? `Set: ${baseTickUpper}` : ''}</span>
                  </RowBetween>
                </ThemedText.Main>
                {fixedMemberTokenInBase >= 0 && showMemberTokenInBase ? (
                  <ThemedText.Gray>
                    <RowBetween>
                      <span>
                        MemberToken
                        <br />
                        in Base position:
                      </span>
                      <span>
                        ${fixedMemberTokenInBase.toFixed(0)}
                        <br />
                        {((fixedMemberTokenInBase / vaultStats.memberTokenValue) * 100).toFixed(2)}%
                      </span>
                    </RowBetween>
                  </ThemedText.Gray>
                ) : (
                  <ThemedText.Main>
                    {showMemberTokenInBase && (
                      <RowBetween>
                        <span>
                          MemberToken
                          <br />
                          in Base position:
                        </span>
                        <span>
                          ${memberInBaseUSD.toFixed(0)}
                          <br />
                          {memberInBasePercent}%
                        </span>
                      </RowBetween>
                    )}
                  </ThemedText.Main>
                )}

                {!showMemberTokenInBase ? (
                  <RowBetween>
                    {isBaseCurrencyOneToken ? (
                      <ButtonSecondary
                        onClick={() => {
                          onFieldAInput(vaultStats.oneTokenValue.toString() ?? '');
                          setShowMemberTokenInBase(true);
                        }}
                        disabled={false}
                        width={'100%'}
                      >
                        Show Member Token in This Position
                      </ButtonSecondary>
                    ) : (
                      <ButtonSecondary
                        onClick={() => {
                          onFieldBInput(vaultStats.oneTokenValue.toString() ?? '');
                          setShowMemberTokenInBase(true);
                        }}
                        disabled={false}
                        width={'100%'}
                      >
                        Show Member Token in This Position
                      </ButtonSecondary>
                    )}
                  </RowBetween>
                ) : (
                  <RowBetween>
                    {baseTickLower && baseTickUpper ? (
                      <ButtonSecondary
                        padding="15px"
                        $borderRadius="20px"
                        width="100%"
                        onClick={() => {
                          setBaseTickLower(0);
                          setBaseTickUpper(0);
                          setFixedMemberTokenInBase(-1);
                          setShowMemberTokenInBase(false);
                        }}
                      >
                        Reset Base Position
                      </ButtonSecondary>
                    ) : (
                      <ButtonSecondary
                        padding="15px"
                        $borderRadius="20px"
                        width="100%"
                        onClick={() => {
                          setBaseTickLower(ticks.LOWER ? ticks.LOWER : 0);
                          setBaseTickUpper(ticks.UPPER ? ticks.UPPER : 0);
                          setFixedMemberTokenInBase(memberInBaseUSD);
                        }}
                      >
                        Set Base Position
                      </ButtonSecondary>
                    )}
                  </RowBetween>
                )}

                <ThemedText.Label>
                  <Trans>Limit Position</Trans>
                </ThemedText.Label>
                <ThemedText.Main>
                  <RowBetween>
                    <span>Current: {ticks.LOWER ? ticks.LOWER : 0}</span>
                    <span>{limitTickLower !== 0 ? `Set: ${limitTickLower}` : ''}</span>
                  </RowBetween>
                  <RowBetween>
                    <span>Current: {ticks.UPPER ? ticks.UPPER : 0}</span>
                    <span>{limitTickUpper !== 0 ? `Set: ${limitTickUpper}` : ''}</span>
                  </RowBetween>
                </ThemedText.Main>
                <ButtonSecondary
                  padding="15px"
                  $borderRadius="20px"
                  width="auto"
                  onClick={() => {
                    setLimitTickLower(ticks.LOWER ? ticks.LOWER : 0);
                    setLimitTickUpper(ticks.UPPER ? ticks.UPPER : 0);
                  }}
                >
                  Set Limit Position
                </ButtonSecondary>

                {vaultStats && <VaultStatsCard vaultStats={vaultStats} />}

                {vaultStats && vaultStats.currentTick !== '' ? (
                  <ButtonSecondary
                    padding="15px"
                    $borderRadius="20px"
                    width="100%"
                    onClick={() => {
                      getVaultStats();
                    }}
                  >
                    Reload Vault Stats
                  </ButtonSecondary>
                ) : (
                  <ButtonSecondary
                    padding="15px"
                    $borderRadius="20px"
                    width="100%"
                    onClick={() => {
                      getVaultStats();
                    }}
                  >
                    Show Vault Stats
                  </ButtonSecondary>
                )}
              </DynamicSection>

              {!hasExistingPosition ? (
                <>
                  <HideMedium>
                    <Buttons />
                  </HideMedium>
                  <RightContainer gap="lg">
                    <DynamicSection gap="md" disabled={!feeAmount || invalidPool}>
                      {!noLiquidity ? (
                        <>
                          <RowBetween>
                            <ThemedText.Label>
                              <Trans>Set Price Range</Trans>
                            </ThemedText.Label>
                          </RowBetween>

                          {price && baseCurrency && quoteCurrency && !noLiquidity && (
                            <AutoRow gap="4px" justify="center" style={{ marginTop: '0.5rem' }}>
                              <span>
                                <ThemedText.Main fontWeight={500} textAlign="center" fontSize={12} color="text1">
                                  Current Price:
                                </ThemedText.Main>
                                <ThemedText.Body fontWeight={500} textAlign="center" fontSize={12} color="text1">
                                  <HoverInlineText
                                    maxCharacters={20}
                                    text={invertPrice ? price.invert().toSignificant(6) : price.toSignificant(6)}
                                  />
                                </ThemedText.Body>
                                <ThemedText.Body color="text2" fontSize={12}>
                                  {quoteCurrency?.symbol} per {baseCurrency.symbol}
                                </ThemedText.Body>
                              </span>
                            </AutoRow>
                          )}

                          <LiquidityChartRangeInput
                            currencyA={baseCurrency ?? undefined}
                            currencyB={quoteCurrency ?? undefined}
                            feeAmount={feeAmount}
                            ticksAtLimit={ticksAtLimit}
                            price={
                              price ? parseFloat((invertPrice ? price.invert() : price).toSignificant(8)) : undefined
                            }
                            priceLower={priceLower}
                            priceUpper={priceUpper}
                            onLeftRangeInput={onLeftRangeInput}
                            onRightRangeInput={onRightRangeInput}
                            interactive={!hasExistingPosition}
                          />
                        </>
                      ) : (
                        <AutoColumn gap="md">
                          <RowBetween>
                            <ThemedText.Label>
                              <Trans>Set Starting Price</Trans>
                            </ThemedText.Label>
                          </RowBetween>
                          {noLiquidity && (
                            <BlueCard
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: '1rem 1rem'
                              }}
                            >
                              <ThemedText.Body
                                fontSize={14}
                                style={{ fontWeight: 500 }}
                                textAlign="left"
                                color={theme.primaryText1}
                              >
                                <Trans>
                                  This pool must be initialized before you can add liquidity. To initialize, select a
                                  starting price for the pool. Then, enter your liquidity price range and deposit
                                  amount. Gas fees will be higher than usual due to the initialization transaction.
                                </Trans>
                              </ThemedText.Body>
                            </BlueCard>
                          )}
                          <OutlineCard padding="12px">
                            <StyledInput
                              className="start-price-input"
                              value={startPriceTypedValue}
                              onUserInput={onStartPriceInput}
                            />
                          </OutlineCard>
                          <RowBetween style={{ backgroundColor: theme.bg1, padding: '12px', borderRadius: '12px' }}>
                            <ThemedText.Main>
                              <span>Current {baseCurrency?.symbol} Price:</span>
                            </ThemedText.Main>
                            <ThemedText.Main>
                              {price ? (
                                <ThemedText.Main>
                                  <RowFixed>
                                    <HoverInlineText
                                      maxCharacters={20}
                                      text={invertPrice ? price?.invert()?.toSignificant(5) : price?.toSignificant(5)}
                                    />{' '}
                                    <span style={{ marginLeft: '4px' }}>{quoteCurrency?.symbol}</span>
                                  </RowFixed>
                                </ThemedText.Main>
                              ) : (
                                '-'
                              )}
                            </ThemedText.Main>
                          </RowBetween>
                        </AutoColumn>
                      )}
                    </DynamicSection>

                    <DynamicSection
                      gap="md"
                      disabled={!feeAmount || invalidPool || (noLiquidity && !startPriceTypedValue)}
                    >
                      <StackedContainer>
                        <StackedItem style={{ opacity: showCapitalEfficiencyWarning ? '0.05' : 1 }}>
                          <AutoColumn gap="md">
                            {noLiquidity && (
                              <RowBetween>
                                <ThemedText.Label>
                                  <Trans>Set Price Range</Trans>
                                </ThemedText.Label>
                              </RowBetween>
                            )}
                            <RangeSelector
                              priceLower={priceLower}
                              priceUpper={priceUpper}
                              getDecrementLower={getDecrementLower}
                              getIncrementLower={getIncrementLower}
                              getDecrementUpper={getDecrementUpper}
                              getIncrementUpper={getIncrementUpper}
                              onLeftRangeInput={onLeftRangeInput}
                              onRightRangeInput={onRightRangeInput}
                              currencyA={baseCurrency}
                              currencyB={quoteCurrency}
                              feeAmount={feeAmount}
                              ticksAtLimit={ticksAtLimit}
                            />
                            <AutoColumn gap="md">
                              <RowBetween>
                                <LeftRangeButton
                                  setLeftRangeToMin={() => {
                                    getSetLeftRangeToMin();
                                  }}
                                />
                                <RightRangeButton
                                  setRightRangeToMax={() => {
                                    getSetRightRangeToMax();
                                  }}
                                />
                              </RowBetween>
                            </AutoColumn>
                          </AutoColumn>
                        </StackedItem>

                        {showCapitalEfficiencyWarning && (
                          <StackedItem zIndex={1}>
                            <YellowCard
                              padding="15px"
                              $borderRadius="12px"
                              height="100%"
                              style={{
                                borderColor: theme.yellow3,
                                border: '1px solid'
                              }}
                            >
                              <AutoColumn gap="8px" style={{ height: '100%' }}>
                                <RowFixed>
                                  <AlertTriangle stroke={theme.yellow3} size="16px" />
                                  <ThemedText.Yellow ml="12px" fontSize="15px">
                                    <Trans>Efficiency Comparison</Trans>
                                  </ThemedText.Yellow>
                                </RowFixed>
                                <RowFixed>
                                  <ThemedText.Yellow ml="12px" fontSize="13px" margin={0} fontWeight={400}>
                                    <Trans>
                                      Full range positions may earn less fees than concentrated positions. Learn more{' '}
                                      <ExternalLink
                                        style={{ color: theme.yellow3, textDecoration: 'underline' }}
                                        href={
                                          'https://help.uniswap.org/en/articles/5434296-can-i-provide-liquidity-over-the-full-range-in-v3'
                                        }
                                      >
                                        here
                                      </ExternalLink>
                                      .
                                    </Trans>
                                  </ThemedText.Yellow>
                                </RowFixed>
                                <Row>
                                  <ButtonYellow
                                    padding="8px"
                                    marginRight="8px"
                                    $borderRadius="8px"
                                    width="auto"
                                    onClick={() => {
                                      setShowCapitalEfficiencyWarning(false);
                                      getSetFullRange();
                                    }}
                                  >
                                    <ThemedText.Black fontSize={13} color="black">
                                      <Trans>I understand</Trans>
                                    </ThemedText.Black>
                                  </ButtonYellow>
                                </Row>
                              </AutoColumn>
                            </YellowCard>
                          </StackedItem>
                        )}
                      </StackedContainer>

                      {outOfRange ? (
                        <YellowCard padding="8px 12px" $borderRadius="12px">
                          <RowBetween>
                            <AlertTriangle stroke={theme.yellow3} size="16px" />
                            <ThemedText.Yellow ml="12px" fontSize="12px">
                              <Trans>
                                Your position will not earn fees or be used in trades until the market price moves into
                                your range.
                              </Trans>
                            </ThemedText.Yellow>
                          </RowBetween>
                        </YellowCard>
                      ) : null}

                      {invalidRange ? (
                        <YellowCard padding="8px 12px" $borderRadius="12px">
                          <RowBetween>
                            <AlertTriangle stroke={theme.yellow3} size="16px" />
                            <ThemedText.Yellow ml="12px" fontSize="12px">
                              <Trans>Invalid range selected. The min price must be lower than the max price.</Trans>
                            </ThemedText.Yellow>
                          </RowBetween>
                        </YellowCard>
                      ) : null}
                    </DynamicSection>

                    {/* <ButtonPrimary
                      onClick={() => getPositions()}
                      disabled={baseTickLower === 0 || baseTickUpper === 0 || limitTickLower === 0 || limitTickUpper === 0}
                      width={'100%'}
                    >Print positions</ButtonPrimary> */}

                    <MediumOnly>
                      <Buttons />
                    </MediumOnly>
                  </RightContainer>
                </>
              ) : (
                <Buttons />
              )}
            </ResponsiveTwoColumns>
          </Wrapper>
        </PageWrapper>
        {addIsUnsupported && (
          <UnsupportedCurrencyFooter
            show={addIsUnsupported}
            currencies={[currencies.CURRENCY_A, currencies.CURRENCY_B]}
          />
        )}
      </ScrollablePage>
      <SwitchLocaleLink />
    </>
  );
}
