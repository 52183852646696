import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionMonitoringEventCallback } from 'hooks/useMonitoringEventCallback'
import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { useActiveWeb3React } from '../../hooks/web3'
import { addTransaction, TransactionInfo, TransactionType } from './actions'
import { TransactionDetails } from './reducer'

// helper that can take a ethers library transaction response and add it to the list of transactions
export function useTransactionAdder(): (response: TransactionResponse, info: TransactionInfo) => void {
  const { chainId, account } = useActiveWeb3React()
  const dispatch = useAppDispatch()

  const logMonitoringEvent = useTransactionMonitoringEventCallback()

  return useCallback(
    (response: TransactionResponse, info: TransactionInfo) => {
      if (!account) return
      if (!chainId) return

      const { hash } = response
      if (!hash) {
        throw Error('No transaction hash found.')
      }
      dispatch(addTransaction({ hash, from: account, info, chainId }))

      logMonitoringEvent(info, response)
    },
    [account, chainId, dispatch, logMonitoringEvent]
  )
}

// returns all the transactions for the current chain
export function useAllTransactions(): { [txHash: string]: TransactionDetails } {
  const { chainId } = useActiveWeb3React()

  const state = useAppSelector((state) => state.transactions)

  return chainId ? state[chainId] ?? {} : {}
}

export function useTransaction(transactionHash?: string): TransactionDetails | undefined {
  const allTransactions = useAllTransactions()

  if (!transactionHash) {
    return undefined
  }

  return allTransactions[transactionHash]
}

export function useIsTransactionPending(transactionHash?: string): boolean {
  const transactions = useAllTransactions()

  if (!transactionHash || !transactions[transactionHash]) return false

  return !transactions[transactionHash].receipt
}

export function useIsTransactionConfirmed(transactionHash?: string): boolean {
  const transactions = useAllTransactions()

  if (!transactionHash || !transactions[transactionHash]) return false

  return Boolean(transactions[transactionHash].receipt)
}

/**
 * Returns whether a transaction happened in the last day (86400 seconds * 1000 milliseconds / second)
 * @param tx to check for recency
 */
export function isTransactionRecent(tx: TransactionDetails): boolean {
  return new Date().getTime() - tx.addedTime < 86_400_000
}

// returns whether a token has a pending approval transaction
export function useHasPendingApproval(tokenAddress: string | undefined, spender: string | undefined): boolean {
  const allTransactions = useAllTransactions()
  return useMemo(
    () =>
      typeof tokenAddress === 'string' &&
      typeof spender === 'string' &&
      Object.keys(allTransactions).some((hash) => {
        const tx = allTransactions[hash]
        if (!tx) return false
        if (tx.receipt) {
          return false
        } else {
          if (tx.info.type !== TransactionType.APPROVAL) return false
          return tx.info.spender === spender && tx.info.tokenAddress === tokenAddress && isTransactionRecent(tx)
        }
      }),
    [allTransactions, spender, tokenAddress]
  )
}

// returns whether a vault has a pending rebalance transaction
export function useHasPendingRebalance(vaultAddress: string | undefined): boolean {
  const allTransactions = useAllTransactions()
  return useMemo(
    () =>
      typeof vaultAddress === 'string' &&
      Object.keys(allTransactions).some((hash) => {
        const tx = allTransactions[hash]
        if (!tx) return false
        if (tx.receipt) {
          return false
        } else {
          if (tx.info.type !== TransactionType.REBALANCE) return false
          return tx.info.vaultAddress === vaultAddress && isTransactionRecent(tx)
        }
      }),
    [allTransactions, vaultAddress]
  )
}

// returns whether a vault has a pending set twap period transaction
export function useHasPendingTwapPeriod(vaultAddress: string | undefined): boolean {
  const allTransactions = useAllTransactions()
  return useMemo(
    () =>
      typeof vaultAddress === 'string' &&
      Object.keys(allTransactions).some((hash) => {
        const tx = allTransactions[hash]
        if (!tx) return false
        if (tx.receipt) {
          return false
        } else {
          if (tx.info.type !== TransactionType.TWAP_PERIOD) return false
          return tx.info.vaultAddress === vaultAddress && isTransactionRecent(tx)
        }
      }),
    [allTransactions, vaultAddress]
  )
}

// returns whether a vault has a pending set hysteresis transaction
export function useHasPendingHysteresis(vaultAddress: string | undefined): boolean {
  const allTransactions = useAllTransactions()
  return useMemo(
    () =>
      typeof vaultAddress === 'string' &&
      Object.keys(allTransactions).some((hash) => {
        const tx = allTransactions[hash]
        if (!tx) return false
        if (tx.receipt) {
          return false
        } else {
          if (tx.info.type !== TransactionType.TWAP_PERIOD) return false
          return tx.info.vaultAddress === vaultAddress && isTransactionRecent(tx)
        }
      }),
    [allTransactions, vaultAddress]
  )
}

// returns whether a vault has a pending set max total supply transaction
export function useHasPendingMaxTotalSupply(vaultAddress: string | undefined): boolean {
  const allTransactions = useAllTransactions()
  return useMemo(
    () =>
      typeof vaultAddress === 'string' &&
      Object.keys(allTransactions).some((hash) => {
        const tx = allTransactions[hash]
        if (!tx) return false
        if (tx.receipt) {
          return false
        } else {
          if (tx.info.type !== TransactionType.TWAP_PERIOD) return false
          return tx.info.vaultAddress === vaultAddress && isTransactionRecent(tx)
        }
      }),
    [allTransactions, vaultAddress]
  )
}

// returns whether a vault has a pending set max deposit transaction
export function useHasPendingMaxDeposit(vaultAddress: string | undefined): boolean {
  const allTransactions = useAllTransactions()
  return useMemo(
    () =>
      typeof vaultAddress === 'string' &&
      Object.keys(allTransactions).some((hash) => {
        const tx = allTransactions[hash]
        if (!tx) return false
        if (tx.receipt) {
          return false
        } else {
          if (tx.info.type !== TransactionType.MAX_DEPOSIT) return false
          return tx.info.vaultAddress === vaultAddress && isTransactionRecent(tx)
        }
      }),
    [allTransactions, vaultAddress]
  )
}

// returns whether a vault has a pending set max total supply transaction
export function useHasPendingAffiliate(vaultAddress: string | undefined): boolean {
  const allTransactions = useAllTransactions()
  return useMemo(
    () =>
      typeof vaultAddress === 'string' &&
      Object.keys(allTransactions).some((hash) => {
        const tx = allTransactions[hash]
        if (!tx) return false
        if (tx.receipt) {
          return false
        } else {
          if (tx.info.type !== TransactionType.TWAP_PERIOD) return false
          return tx.info.vaultAddress === vaultAddress && isTransactionRecent(tx)
        }
      }),
    [allTransactions, vaultAddress]
  )
}
