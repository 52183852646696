import { AutoColumn } from 'components/Column'
import styled from 'styled-components/macro'
import { ExternalLink } from 'theme'

const Wrapper = styled.div`
  padding-top: 12px;
  font-size: 12px;
`

export function Review({
  vaultAddress,
  adminAddress,
  safeAddress,
  currentTick,
  baseTickLower,
  baseTickUpper,
  limitTickLower,
  limitTickUpper,
  swap,
}: {
  vaultAddress?: string
  adminAddress?: string
  safeAddress?: string
  swap?: string
  currentTick?: string
  baseTickLower?: number
  baseTickUpper?: number
  limitTickLower?: number
  limitTickUpper?: number
}) {
  return (
    <Wrapper>
      <AutoColumn gap="lg">
        <ExternalLink href={'https://etherscan.io/address/' + vaultAddress}>{'Vault: ' + vaultAddress}</ExternalLink>
      </AutoColumn>
      <AutoColumn gap="lg">
        <ExternalLink href={'https://etherscan.io/address/' + adminAddress}>{'Admin: ' + adminAddress}</ExternalLink>
      </AutoColumn>
      <AutoColumn gap="lg">
        <ExternalLink href={'https://etherscan.io/address/' + safeAddress}>{'Safe: ' + safeAddress}</ExternalLink>
      </AutoColumn>
      <AutoColumn gap="lg">Current Tick: [{currentTick}]</AutoColumn>
      <AutoColumn gap="lg">
        Base Position: [{baseTickLower}] - [{baseTickUpper}]
      </AutoColumn>
      <AutoColumn gap="lg">
        Limit Position: [{limitTickLower}] - [{limitTickUpper}]
      </AutoColumn>
      <AutoColumn gap="lg">Swap: {swap}</AutoColumn>
    </Wrapper>
  )
}
