import { BigNumber } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import { useCallback, useMemo } from 'react'

import { TransactionType } from '../state/transactions/actions'
import { useHasPendingMaxTotalSupply, useTransactionAdder } from '../state/transactions/hooks'
import { calculateGasMargin } from '../utils/calculateGasMargin'
import { useVaultContract } from './useContract'
import { useActiveWeb3React } from './web3'

export enum MaxTotalSupplyState {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
}

// returns a variable indicating the state of the max total supply setting and a function which sets max total supply if necessary or early returns
export function useMaxTotalSupplyCallback(vaultAddress?: string, maxTotalSupplyInput?: string): [() => Promise<void>] {
  const { chainId } = useActiveWeb3React()
  const pendingTwapPeriod = useHasPendingMaxTotalSupply(vaultAddress)

  const maxTotalSupplyState: MaxTotalSupplyState = useMemo(() => {
    return pendingTwapPeriod ? MaxTotalSupplyState.PENDING : MaxTotalSupplyState.IDLE
  }, [pendingTwapPeriod])

  const vaultContract = useVaultContract(vaultAddress)
  const addTransaction = useTransactionAdder()

  const maxTotalSupply = useCallback(async (): Promise<void> => {
    if (maxTotalSupplyState !== MaxTotalSupplyState.IDLE) {
      console.error('MaxTotalSupply was called unnecessarily')
      return
    }
    if (!chainId) {
      console.error('no chainId')
      return
    }
    if (!vaultAddress) {
      console.error('no vault')
      return
    }
    if (!vaultContract) {
      console.error('vaultContract is null')
      return
    }
    if (!maxTotalSupplyInput) {
      console.error('missing maxTotalSupply value')
      return
    }

    return vaultContract
      .setMaxTotalSupply(maxTotalSupplyInput + '000000000000000000', {
        gasLimit: calculateGasMargin(BigNumber.from('160000')),
      })
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          type: TransactionType.MAX_TOTAL_SUPPLY,
          vaultAddress,
          maxTotalSupplyValue: maxTotalSupplyInput,
        })
      })
      .catch((error: Error) => {
        console.debug('Failed to set twap period: ', error)
        throw error
      })
  }, [maxTotalSupplyState, maxTotalSupplyInput, chainId, vaultAddress, vaultContract, addTransaction])

  return [maxTotalSupply]
}
