import { BigNumber } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import { Vault, VaultStats } from 'constants/vaults'
import { useCallback, useMemo } from 'react'

import { TransactionType } from '../state/transactions/actions'
import { useTransactionAdder } from '../state/transactions/hooks'
import { calculateGasMargin } from '../utils/calculateGasMargin'
import { useVaultAdmin1Contract, useVaultContract } from './useContract'
import { useActiveWeb3React } from './web3'

export enum RebalanceState {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
}

// returns a variable indicating the state of the rebalance and a function which rebalances if necessary or early returns
export function useRebalanceCallback(
  vault?: Vault,
  stats?: VaultStats,
  baseLowerTick?: number,
  baseUpperTick?: number,
  limitLowerTick?: number,
  limitUpperTick?: number,
  swap?: string
): [RebalanceState, () => Promise<void>] {
  const { chainId } = useActiveWeb3React()
  // const pendingRebalance = useHasPendingRebalance(vaultAddress)
  const pendingRebalance = false

  const rebalanceState: RebalanceState = useMemo(() => {
    return pendingRebalance ? RebalanceState.PENDING : RebalanceState.IDLE
  }, [pendingRebalance])

  const vaultContract = useVaultContract(vault?.address)
  const vaultAdmin1Contract = useVaultAdmin1Contract(vault?.admin)
  const addTransaction = useTransactionAdder()

  const rebalance = useCallback(async (): Promise<void> => {
    if (rebalanceState !== RebalanceState.IDLE) {
      console.error('rebalance was called unnecessarily')
      return
    }
    if (!chainId) {
      console.error('no chainId')
      return
    }
    if (!stats) {
      console.error('no stats')
      return
    }
    if (!vault || vault.address === '' || !vaultContract) {
      console.error('no vault')
      return
    }
    if (!baseLowerTick || !baseUpperTick || !limitLowerTick || !limitUpperTick) {
      console.error('missing ticks')
      return
    }

    /*const estimatedGas = await vaultContract.estimateGas.rebalance(
      baseLowerTick.toString(),
      baseUpperTick.toString(),
      limitLowerTick.toString(),
      limitUpperTick.toString(),
      swap ? swap : '0'
    )*/

    if (vault.admin !== '' && vault.safe !== '') {
      if (!vaultAdmin1Contract) {
        console.error('no vault admin')
        return
      }

      /*console.log(vault.safe)
      console.log(vault.address)
      console.log(stats.currentTick)
      console.log(baseLowerTick)
      console.log(baseUpperTick)
      console.log(limitLowerTick)
      console.log(limitUpperTick)*/

      return vaultAdmin1Contract
        .rebalance(
          vault.safe,
          vault.address,
          stats.currentTick,
          baseLowerTick.toString(),
          baseUpperTick.toString(),
          limitLowerTick.toString(),
          limitUpperTick.toString(),
          swap ? swap : '0',
          {
            gasLimit: calculateGasMargin(BigNumber.from('1600000')),
          }
        )
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            type: TransactionType.REBALANCE,
            vaultAddress: vault.address,
            baseLowerTick,
            baseUpperTick,
            limitLowerTick,
            limitUpperTick,
            swap: swap ? swap : '0',
          })
        })
        .catch((error: Error) => {
          console.debug('Failed to rebalance vault', error)
          throw error
        })
    } else {
      return vaultContract
        .rebalance(
          baseLowerTick.toString(),
          baseUpperTick.toString(),
          limitLowerTick.toString(),
          limitUpperTick.toString(),
          swap ? swap : '0',
          {
            gasLimit: calculateGasMargin(BigNumber.from('1600000')),
          }
        )
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            type: TransactionType.REBALANCE,
            vaultAddress: vault.address,
            baseLowerTick,
            baseUpperTick,
            limitLowerTick,
            limitUpperTick,
            swap: swap ? swap : '0',
          })
        })
        .catch((error: Error) => {
          console.debug('Failed to rebalance vault', error)
          throw error
        })
    }
  }, [
    rebalanceState,
    chainId,
    vault,
    stats,
    vaultContract,
    vaultAdmin1Contract,
    baseLowerTick,
    baseUpperTick,
    limitLowerTick,
    limitUpperTick,
    swap,
    addTransaction,
  ])

  return [rebalanceState, rebalance]
}
