import { BigNumber } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import { useCallback, useMemo } from 'react'

import { TransactionType } from '../state/transactions/actions'
import { useHasPendingHysteresis, useTransactionAdder } from '../state/transactions/hooks'
import { calculateGasMargin } from '../utils/calculateGasMargin'
import { useVaultContract } from './useContract'
import { useActiveWeb3React } from './web3'

export enum HysteresisState {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
}

// returns a variable indicating the state of the hysteresis setting and a function which sets hysteresis if necessary or early returns
export function useHysteresisCallback(vaultAddress?: string, hysteresisInput?: string): [() => Promise<void>] {
  const { chainId } = useActiveWeb3React()
  const pendingHysteresis = useHasPendingHysteresis(vaultAddress)

  const hysteresisState: HysteresisState = useMemo(() => {
    return pendingHysteresis ? HysteresisState.PENDING : HysteresisState.IDLE
  }, [pendingHysteresis])

  const vaultContract = useVaultContract(vaultAddress)
  const addTransaction = useTransactionAdder()

  const hysteresis = useCallback(async (): Promise<void> => {
    if (hysteresisState !== HysteresisState.IDLE) {
      console.error('Hysteresis setting was called unnecessarily')
      return
    }
    if (!chainId) {
      console.error('no chainId')
      return
    }
    if (!vaultAddress) {
      console.error('no vault')
      return
    }
    if (!vaultContract) {
      console.error('vaultContract is null')
      return
    }
    if (!hysteresisInput) {
      console.error('missing hysteresis value')
      return
    }

    return vaultContract
      .setHysteresis(
        BigNumber.from(Number(hysteresisInput) * 1000)
          .mul(10 ** 13)
          .toString(),
        {
          gasLimit: calculateGasMargin(BigNumber.from('160000')),
        }
      )
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          type: TransactionType.HYSTERESIS,
          vaultAddress,
          hysteresisValue: hysteresisInput,
        })
      })
      .catch((error: Error) => {
        console.debug('Failed to set hysteresis: ', error)
        throw error
      })
  }, [hysteresisState, hysteresisInput, chainId, vaultAddress, vaultContract, addTransaction])

  return [hysteresis]
}
