import { createAction } from '@reduxjs/toolkit'

export enum Field {
  CURRENCY_A = 'CURRENCY_A',
  CURRENCY_B = 'CURRENCY_B',
}

export enum InputField {
  TWAP_PERIOD = 'TWAP_PERIOD',
}

export enum Bound {
  LOWER = 'LOWER',
  UPPER = 'UPPER',
}

export const typeInput =
  createAction<{ field: Field; typedValue: string; noLiquidity: boolean }>('mintV3/typeInputMint')
// export const typeInputSettings = createAction<{ typedValue: string }>('mintV3/typeInputSettings')
export const typeTwapPeriodInput = createAction<{ typedValue: string }>('mintV3/typeTwapPeriodInput')
export const typeAffiliateInput = createAction<{ typedValue: string }>('mintV3/typeAffiliateInput')
export const typeHysteresisInput = createAction<{ typedValue: string }>('mintV3/typeHysteresisInput')
export const typeMaxTotalSupplyInput = createAction<{ typedValue: string }>('mintV3/typeMaxTotalSupplyInput')
export const typeMaxDeposit0Input = createAction<{ typedValue: string }>('mintV3/typeMaxDeposit0Input')
export const typeMaxDeposit1Input = createAction<{ typedValue: string }>('mintV3/typeMaxDeposit1Input')
export const typeStartPriceInput = createAction<{ typedValue: string }>('mintV3/typeStartPriceInput')
export const typeLeftRangeInput = createAction<{ typedValue: string }>('mintV3/typeLeftRangeInput')
export const typeRightRangeInput = createAction<{ typedValue: string }>('mintV3/typeRightRangeInput')
export const resetMintState = createAction<void>('mintV3/resetMintState')
export const setFullRange = createAction<void>('mintV3/setFullRange')
export const setLeftRangeToMin = createAction<void>('mintV3/setLeftRangeToMin')
export const setRightRangeToMax = createAction<void>('mintV3/setRightRangeToMax')
